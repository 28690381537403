import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { ConfigService } from "../services/config.service"

@Injectable({
  providedIn: "root"
})
export class ScopeInterceptorService implements HttpInterceptor {

  constructor(
    private configService: ConfigService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customerId = this.configService.getCustomerId()
    if (customerId !== null) {
      request = request.clone({
        setParams: {
          cid: customerId
        }
      })
    }
    return next.handle(request)
  }
}
