import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { Customer } from "../../interfaces/customer"
import { InvocationResult } from "../../interfaces/invocation-result"
import { ConfigService } from "../config.service"

@Injectable({
  providedIn: "root"
})
export class CustomerService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    // do nothing
  }

  getCustomer(): Observable<Customer> {
    return this.httpClient.get(environment.cmnUserApiBaseUrl + "/customers/" + this.configService.getCustomerId()).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
