<div class="humehealth" (click)="goToHomePage()">
  <div class="humehealth__body">
    <div class="humehealth__header">
      <app-customer-logo></app-customer-logo>
    </div>

    <div class="humehealth__content">
      <div class="humehealth__title">{{ 'IDLE.TOUCH_SCREEN' | translate }}</div>
      <img src="assets/images/hand-icon.svg" [alt]="'Startup' | translate">
    </div>
  </div>
</div>
