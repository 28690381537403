import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { EmailDto, SentEmailDto } from "../../interfaces/dto/email-dto"
import { Email } from "../../interfaces/email"
import { InvocationResult } from "../../interfaces/invocation-result"

@Injectable({
  providedIn: "root"
})
export class EmailService {

  constructor(
    private httpClient: HttpClient
  ) {
    // do nothing
  }

  sendEmail(email: Email): Observable<SentEmailDto> {
    const emailDto = email as EmailDto
    return this.httpClient.post(environment.cmnEmailApiBaseUrl + "/emails", emailDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
