import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"
import { LoaderService } from "../utilities/loader/loader.service"

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, public loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.loaderService.setLoader(false)
            return throwError(err)
        }))
    }
}
