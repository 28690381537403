import {Component, Input, OnInit} from "@angular/core"
import {AbstractControl, FormGroup} from "@angular/forms"
import {Observable} from "rxjs"
import {KeyboardInputService} from "../keyboard/keyboard-input.service"
import {ScreenService} from "../../shared/services/screen.service"

@Component({
  selector: "app-custom-input",
  templateUrl: "./custom-input.component.html",
  styleUrls: ["./custom-input.component.css"]
})
export class CustomInputComponent implements OnInit {
  isKiosk$: Observable<boolean>

  @Input() label: string
  @Input() controlName: string
  @Input() parentForm: FormGroup
  @Input() required = false
  @Input() matKeyboard = "keyboard_fr"
  @Input() type = "text"

  constructor(
    private keyboardInputService: KeyboardInputService,
    private screenService: ScreenService,
  ) { }

  ngOnInit(): void {
    this.isKiosk$ = this.screenService.isKiosk()
  }

  onInputFocus(target: AbstractControl | HTMLInputElement, translationKey: string): void {
    this.isKiosk$.subscribe((isKiosk) => {
      if (isKiosk) {
        let value: string

        if (target instanceof AbstractControl) {
          value = target.value || ""
        }
        else if (target instanceof HTMLInputElement) {
          value = target.value || ""
        } else {
          return
        }

        this.keyboardInputService.setInputValue(value)
        this.keyboardInputService.setInputType(this.type)
        this.keyboardInputService.setInputMatKeyboard(this.matKeyboard)
        this.keyboardInputService.setInputTranslationKey(translationKey)
        this.keyboardInputService.setInputTarget(target)
      }
    })
  }
}
