<div class="modal-wrapper">
    <div class="modal-header">
        <h1 class="mobile-heading" i18n>Attention</h1>
    </div>
    <div class="modal-body">
        <div class="modal-message">
            <p>{{ 'EnteredDataDeleted' | translate }}</p>
        </div>
    </div>
    <div class="modal-button">
        <button type="button" class="btn btn-outline-success secondary" (click)="navigateToHome()">
            {{ 'ReturnHome' | translate }}</button>
        <button type="button" class="btn btn-secondary" aria-label="Close" data-dismiss="modal"
        (click)="closeModel()">{{ 'ContinueEntry' | translate }}</button>
    </div>
</div>
