import {
  AfterViewChecked, ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from "@angular/core"
import {AbstractControl} from "@angular/forms"
import {Subscription} from "rxjs/internal/Subscription"
import {KeyboardInputService} from "./keyboard-input.service"

@Component({
  selector: "app-keyboard-input",
  templateUrl: "./keyboard-input.component.html",
  styleUrls: ["./keyboard-input.component.css"]
})
export class KeyboardInputComponent implements OnInit {
  @ViewChild("keyboardInput", {static: false}) keyboardInputElement: ElementRef

  label: string | null = null
  type = "text"
  matKeyboard = "keyboard_fr"
  inputValue = ""
  subscriptions: Subscription[] = []
  targetControl: AbstractControl | HTMLInputElement | null = null
  isVisible = false
  lastInput = ""

  constructor(
    private keyboardInputService: KeyboardInputService,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.keyboardInputService.inputCurrentValue$.subscribe(value => {
        if (value !== null) {
          this.isVisible = true
          this.inputValue = value
          setTimeout(() => this.keyboardInputElement.nativeElement.focus(), 0)
        } else {
          this.isVisible = false
        }
      }),
      this.keyboardInputService.inputLabel$.subscribe(label => {
        this.label = label
      }),
      this.keyboardInputService.setInputMatKeyboard$.subscribe(matKeyboard => {
        this.matKeyboard = matKeyboard
      }),
      this.keyboardInputService.inputType$.subscribe(type => {
        this.type = type
      }),
      this.keyboardInputService.inputTarget$.subscribe(target => {
        this.targetControl = target
      })
    )
  }

  onInput($event): void {
    if (this.type === "date") {
      this.lastInput = this.inputValue.replace(/-/g, "")
      this.inputValue = this.lastInput.slice(0, 2)
      this.inputValue += this.lastInput.length > 2 ? "-" : ""
      this.inputValue += this.lastInput.slice(2, 4)
      this.inputValue += this.lastInput.length > 4 ? "-" : ""
      this.inputValue += this.lastInput.slice(4, 8)
    }
  }

  onClose(): void {
    if (this.type === "date") {
      this.inputValue = this.inputValue.split("-").reverse().join("-")
    }
    if (this.targetControl instanceof HTMLInputElement) {
      this.targetControl.value = this.inputValue
    } else if (this.targetControl instanceof AbstractControl) {
      this.targetControl.setValue(this.inputValue)
    }

    this.keyboardInputService.clearInput()

    this.inputValue = ""
    this.isVisible = false
  }

  onInputChange(value: string): void {
    this.inputValue = value
  }
}
