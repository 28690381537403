export interface Room {
  id?: string
  number: string
  level: number
  sides: Side[]
  disable: false
  wing: string
}

export interface RoomSelect {
  number: string,
  available: boolean
}

export enum Side {
  DOOR = "DOOR",
  FACE = "FACE",
  WINDOW = "WINDOW"
}
