import {Injectable, OnDestroy} from "@angular/core"
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot, Params, ActivatedRoute
} from "@angular/router"

import {Observable, of, Subscription} from "rxjs"
import {InstitutionService} from "../../services/institution.service"
import {ConfigService} from "../../services/config.service"
import {map} from "rxjs/operators"

@Injectable({
  providedIn: "root"
})
export class InstitutionResolver implements OnDestroy, Resolve<boolean> {

  subscriptions: Subscription = new Subscription()

  constructor(
    private institutionService: InstitutionService,
    private configService: ConfigService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    if (this.configService.getInstitutionId()) {
      return of(true)
    }

    return this.recoveryInstitutionFromUrl()
  }

  recoveryInstitutionFromUrl(): Observable<boolean> {
    let institutionLinkId: string
    if (location.hostname !== "localhost") {
      institutionLinkId = location.hostname.split(".")[0]
    } else {
      institutionLinkId = "1"
    }

    return this.institutionService.getCustomerInstitutionIdFromInstitutionLinkId(institutionLinkId).pipe(
      map(ciId => {
        this.configService.setInstitutionId(ciId.institutionId)
        this.configService.setCustomerId(ciId.customerId)
        return true
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
