import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { Observable, of, Subscription, zip } from "rxjs"
import { mergeMap } from "rxjs/operators"
import { CreateUserSubscriptionOrderOpen } from "src/app/shared/interfaces/create-user-subscription-order"
import { PaymentPoint } from "src/app/shared/interfaces/payment"
import { Subscriptions } from "src/app/shared/interfaces/subscription"
import { ScreenService } from "src/app/shared/services/screen.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import { LoaderService } from "src/app/shared/utilities/loader/loader.service"
import { StripeComponent } from "../stripe/stripe.component"
import { UserSubscriptionStripeOrderDto } from "../../shared/interfaces/dto/user-subscription-dto"
import { Institution } from "../../shared/interfaces/institution"
import { ConfigService } from "../../shared/services/config.service"

@Component({
  selector: "app-order-validate-stripe",
  templateUrl: "./order-validate-stripe.component.html",
  styleUrls: ["./order-validate-stripe.component.css"]
})

export class OrderValidateStripeComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  isKiosk$: Observable<boolean>

  @Output()
  nextStep: EventEmitter<any> = new EventEmitter()

  @Input()
  createUserSubscriptionOrderOpen: CreateUserSubscriptionOrderOpen

  @Input()
  institution: Institution

  @Output()
  createdSubscriptions: EventEmitter<Subscriptions> = new EventEmitter()

  @ViewChild(StripeComponent)
  stripe: StripeComponent
  detailForm: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private screenService: ScreenService,
    private configService: ConfigService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.isKiosk$ = this.screenService.isKiosk()
  }

  initForm(): void {
    let form: { [key: string]: any }
    form = {
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    }

    if (this.institution.manageBirthdate) { form.birthdate = ["", Validators.required] }
    if (this.institution.manageEmail || this.createUserSubscriptionOrderOpen.wifi) {
      form.email = ["", (this.institution.mandatoryEmailForWebapp || this.createUserSubscriptionOrderOpen.wifi) ?
        Validators.required : undefined]
    }
    if (this.institution.managePhone) { form.phone = ["", Validators.required] }
    if (this.institution.manageTutor) { form.tutorName = [""] }
    this.detailForm = this.formBuilder.group(form)
  }

  createOrderSubscription(): Observable<UserSubscriptionStripeOrderDto> {
    const userOrderSubscription: CreateUserSubscriptionOrderOpen = {
      tv: this.createUserSubscriptionOrderOpen.tv,
      wifi: this.createUserSubscriptionOrderOpen.wifi,
      user: {
        firstName: this.detailForm.value.firstName,
        lastName: this.detailForm.value.lastName,
        email: this.detailForm.value.email,
        phoneNumber: this.detailForm.value.phone,
        tutorFullName: this.detailForm.value.tutorName,
        birthdate: this.detailForm.value.birthdate
      },
      subscriptionPayment: {
        paymentPoint: PaymentPoint.MOBILE_APPLICATION,
        price: this.createUserSubscriptionOrderOpen.subscriptionPayment.price
      }
    }
    if (userOrderSubscription.wifi) {
      userOrderSubscription.wifi.sessionId = this.configService.getSessionId()
    }
    return this.subscriptionService.createSubscriptionOpen(userOrderSubscription) as Observable<UserSubscriptionStripeOrderDto>
  }

  doPayment(): void {
    this.loaderService.startLoaderOverride(true)
    this.subscription.add(
      this.createOrderSubscription().pipe(
        mergeMap((order) => zip(
            of(order),
            this.stripe.performPayment(order.stripeClientSecret)
          )
        ),
        mergeMap(([order, _]) => this.subscriptionService.payForSubscription(order.id, {})),
      ).subscribe(
        sub => {
          this.loaderService.stopLoaderOverride()
          const emitDatas = {
            wifi: this.createUserSubscriptionOrderOpen.wifi ? sub : null,
            tv: this.createUserSubscriptionOrderOpen.tv ? sub : null
          } as Subscriptions

          if (emitDatas.wifi !== null) {
            emitDatas.wifi.email = this.detailForm.value.email
          }
          this.createdSubscriptions.emit(emitDatas)
          this.nextStep.emit({})
        },
        err => {
          console.log("err => ", err)
          this.loaderService.stopLoaderOverride()
          this.router.navigateByUrl("error")
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
