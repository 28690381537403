<div class="headset-sidebar" [ngClass]="{'sidebar-open': sidebarOpened}">
  <div class="subscription-inner">
    <div class="mobile-block">
      <div class="block-inner">
        <div class="brand">
          <a href="javascript:void(0)"><app-customer-logo></app-customer-logo></a>
        </div>
        <div class="mobile-button" (click)="close()">
          <a href="javascript:void(0)"><span class="close-icon">X</span>Fermer</a>
        </div>
      </div>
    </div>
    <div class="subscribe-title headset-sidebar__title">
      <h1>{{ 'ToOrderHeadset' | translate}}</h1>
      <p>{{ 'OrderSummary' | translate}}</p>
    </div>
    <div class="subscribe-service" *ngIf="headsetOrder">
      <div class="humehealth__label">
        {{ 'Room' | translate }}
      </div>
      <ul class="headset-sidebar__summary">
        <li>
          <span class="service-label">{{ 'Service' | translate }}:</span>
          <p class="service-value">{{headsetOrder.departmentName}}</p>
        </li>
        <li>
          <span class="service-label">{{ 'RoomNumber' | translate }}:</span>
          <p class="service-value">{{headsetOrder.roomNumber}}</p>
        </li>
        <li>
          <span class="service-label">{{ 'Side' | translate }}:</span>
          <p class="service-value">{{ 'ROOM_SIDE.' + headsetOrder.roomSide | translate}}</p>
        </li>
      </ul>
      <div class="subscribe-total headset-sidebar__total" *ngIf="headsetOrder && headsetOrder.headsetPrice">
        <div class="small-title">
          <h6>{{ 'AmountIncl' | translate }}</h6>
          <p> {{ headsetOrder.headsetPrice }} <span class="currency">&#x20AC;</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
