import { Component, OnDestroy } from "@angular/core"
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Subscription } from "rxjs"
import { KioskApiService } from "./shared/services/kiosk/kiosk-api.service"
import { LoaderService } from "./shared/utilities/loader/loader.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnDestroy {

  resultList: string
  subscription: Subscription = new Subscription()

  constructor(
    private translate: TranslateService,
    private loaderService: LoaderService,
    private router: Router,
    private kioskApiService: KioskApiService
  ) {
    this.translate.addLangs(["en", "fr"])
    this.translate.setDefaultLang("fr")
    // set browser language
    const browserLang = translate.getBrowserLang()
    this.translate.use(browserLang.match(/en|fr/) ? browserLang : "en")
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e)
    })
    this.subscription.add(this.kioskApiService.start().subscribe())
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  // Intercept every route of the application
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.setLoader(true)
      window.scrollTo(0, 0)
    }
    if (event instanceof NavigationEnd) {
      this.loaderService.setLoader(false)
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loaderService.setLoader(false)
    }
    if (event instanceof NavigationError) {
      this.loaderService.setLoader(false)
    }
  }
}
