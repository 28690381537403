<div class="mobile-header">
  <div class="header-inner">
    <div class="mobile-brand">
      <a href="javascript:void(0)"><app-customer-logo></app-customer-logo></a>
    </div>
    <div class="men-square">
      <div class="menu-icon" [ngClass]="{'menu-open': menuVisible}" (click)="toggleSidenav()" id="menu-home">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</div>
