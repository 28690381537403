<div class="main-title">
  <p *ngIf="order.tv">{{ 'ChooseTheDurationTv' | translate }}</p>
  <p *ngIf="order.wifi">{{ 'ChooseTheDurationWifi' | translate }}</p>
</div>
<div class="duration-main-block">
  <p class="h2">{{ 'Subscriptionlastday' | translate }}</p>
  <ul class="duration-select-list radio-list">
    <li *ngFor="let periodInDays of predefinedPeriodInDays">
      <div class="custom-radio">
        <input type="radio" class="custom-radio-input" name="duration" (click)="selectPeriod(periodInDays)" />
        <div class="custom-radio-layout">
          {{ periodInDays }}
        </div>
      </div>
    </li>
    <li class="input">
      <div class="custom-radio period-option__custom">
        <input type="radio" class="custom-radio-input" name="duration" (click)="selectCustomPeriodChoice()">
        <div class="custom-radio-layout period-option__custom__content">
          <span i18n>Autre :</span>
          <input #customPeriod type="text" class="form-control" placeholder="-" [formControl]="customPeriodValue"
            (blur)="selectCustomPeriod($event)" *ngIf="kiosk$ | async; else classicCustomPeriod"
            [matKeyboard]="'num'">
          <ng-template #classicCustomPeriod>
            <input #customPeriod type="number" class="form-control" placeholder="-"
              (keyup)="selectCustomPeriod($event)">
          </ng-template>
        </div>
      </div>
    </li>
  </ul>
  <div class="period-select__actions">
    <button *ngIf="order.tv" type="button" class="btn btn-primary secondary arrow-right"
            [disabled]="!order.tv.periodInDays" (click)="goToNextStep()">
      {{ 'Next' | translate }}
    </button>
    <button *ngIf="order.wifi" type="button" class="btn btn-primary secondary arrow-right"
            [disabled]="!order.wifi.periodInDays" (click)="goToNextStep()">
      {{ 'Next' | translate }}
    </button>
  </div>
</div>
