import {Component} from "@angular/core";

export enum PrinterState {
  OFFLINE, ERROR, PAPER_END, COVER_OPENED, OK
}

@Component({
  selector: 'app-kiosk-error-dialog',
  templateUrl: './kiosk-error-dialog.component.html',
  styleUrls: ['./kiosk-error-dialog.component.scss']
})
export class KioskErrorDialogComponent {

  PrinterState = PrinterState

  state = PrinterState.OK

  constructor(
  ) {
    // do nothing
  }

  updateState(state: PrinterState) {
    this.state = state
  }
}
