import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { AbstractControl, FormGroup, ValidatorFn, Validators } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { TvLocation } from "../../shared/interfaces/subscription"
import { Institution } from "../../shared/interfaces/institution"
import { RoomSelect, Side } from "src/app/shared/interfaces/room"
import { Department } from "src/app/shared/interfaces/department"
import { CollectionUtil } from "../../shared/utilities/collection-util"
import { ScreenService } from "../../shared/services/screen.service"

type TvLocationItem = { location: TvLocation, available: boolean, sideName: string }

@Component({
  selector: "app-tv-location-select",
  templateUrl: "./tv-location-select.component.html",
  styleUrls: ["./tv-location-select.component.scss"]
})
export class TvLocationSelectComponent implements OnInit, OnChanges, OnDestroy {

  subscription: Subscription = new Subscription()

  @Input()
  tvLocations: TvLocation[] = []

  @Input()
  availableTvLocations: TvLocation[] = []

  @Input()
  required = true

  @Input()
  allowClear = false

  @Input()
  clearLabel = ""

  @Input()
  enableAction = false

  @Input()
  actionLabel = ""

  @Input()
  showHeader = true

  @Input()
  header = ""

  @Input()
  forceSelection = false

  @Input()
  allowNotAvailableSelect = false

  @Input()
  institution: Institution

  @Input()
  formGroup: FormGroup

  @Input()
  tvLocationFormControlName: string

  @Input()
  healthDepartmentFormControlName: string

  @Input()
  roomNbFormControlName: string

  @Output()
  forceSelectionChanged: EventEmitter<boolean> = new EventEmitter()

  @Output()
  tvLocationChanged: EventEmitter<TvLocation> = new EventEmitter()

  @ViewChild("searchRoomNumber") searchRoomNumber: ElementRef
  @ViewChild("searchHealthDepartment") searchHealthDepartment: ElementRef

  sides: { id: Side, name: string }[] = []
  filteredRoomNumbers: RoomSelect[] = []
  filteredHealthDepartments: Department[] = []
  allTvLocations: TvLocation[] = []
  filteredAvailableTvLocations: TvLocation[] = []
  roomNumbers: RoomSelect[] = []
  healthDepartments: Department[] = []
  tvLocationItems: TvLocationItem[] = []
  formValChangesSubscription: Subscription
  selectedTvLocation: TvLocation = null
  isKiosk$: Observable<boolean>

  constructor(
    private screenService: ScreenService,
    private translateService: TranslateService,
  ) {
    this.initSides()
  }

  private initSides(): void {
    this.sides = Object.keys(Side).map(side => ({
      id: Side[side],
      name: this.translateService.instant("ROOM_SIDE." + side)
    }))
  }

  ngOnInit(): void {
    this.isKiosk$ = this.screenService.isKiosk()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roomSideFormControlName && changes.roomSideFormControlName.currentValue) {
      this.initAvailabilities()
    }

    if (changes.tvLocations && changes.tvLocations.currentValue) {
      this.allTvLocations = changes.tvLocations.currentValue
      this.initAvailabilities()
    }

    if (changes.availableTvLocations && changes.availableTvLocations.currentValue) {
      this.filteredAvailableTvLocations = changes.availableTvLocations.currentValue
      this.initAvailabilities()
    }

    if (changes.formGroup) {
      if (this.formValChangesSubscription) {
        this.formValChangesSubscription.unsubscribe()
      }

      const newFormGroup  = changes.formGroup.currentValue
      if (newFormGroup) {
        this.filterHealthDepartments(this.formGroup.get(this.roomNbFormControlName).value)
        this.filterTvLocationItems(this.formGroup.get(this.healthDepartmentFormControlName).value)
        this.formValChangesSubscription = this.formGroup.valueChanges.subscribe(state => {
          const selectedTvLocationItem = state[this.tvLocationFormControlName]
          const selectedTvLocation = selectedTvLocationItem ? selectedTvLocationItem.location : null
          if (this.selectedTvLocation !== selectedTvLocation) {
            this.selectedTvLocation = selectedTvLocation
            this.tvLocationChanged.emit(selectedTvLocation)
          }
        })
        this.subscription.add(this.formValChangesSubscription)
      } else {
        this.formValChangesSubscription = null
      }
    }
  }

  private initAvailabilities(): void {
    if (!this.tvLocationFormControlName) { return }

    const roomSideControl = this.formGroup.get(this.tvLocationFormControlName)
    if (!roomSideControl) { return }
    const roomSideValidators = [this.onlyAvailableTvLocationValidator()]
    if (this.required) {
      roomSideValidators.push(Validators.required)
    }
    roomSideControl.setValidators(roomSideValidators)
    roomSideControl.updateValueAndValidity()

    if (!this.institution) { return }
    const unorderedRoomNumbers = this.institution.healthDepartments
      .map(d => d.rooms)
      .reduce((acc, cur) => acc.concat(cur), []) // flat room arrays
      .map(r => { // map room availablities
        return { number: r.number, available: this.filteredAvailableTvLocations.some(i => i.roomId === r.id) }
      })
      .reduce((acc, cur) => { // group by room number
        const room = acc.find(r => r.number === cur.number)
        if (room) {
          room.available ||= cur.available
          return acc
        } else {
          return [...acc, cur]
        }
      }, [])

    this.roomNumbers = CollectionUtil.sortAlphaNumericProp(unorderedRoomNumbers, "number")
    this.filteredRoomNumbers = this.roomNumbers
  }

  onSelectRoomNumber(roomNb: RoomSelect): void {
    this.clearHealthDepartments()
    this.clearTvLocations()
    this.filterHealthDepartments(roomNb)
    if (this.filteredHealthDepartments.length === 1) {
      const healthDepartment = this.filteredHealthDepartments[0]
      this.formGroup.get(this.healthDepartmentFormControlName).patchValue(healthDepartment)
      this.onSelectHealthDepartment(healthDepartment)
    }
  }

  private filterHealthDepartments(roomNb: RoomSelect): void {
    if (!this.institution) { return }
    this.healthDepartments = roomNb ? this.institution.healthDepartments
      .filter(dpt => dpt.rooms.find(r => r.number === roomNb.number)) : this.institution.healthDepartments
    this.filteredHealthDepartments = this.healthDepartments
  }

  onSearchRoomNumber(value: any): void {
    this.filteredRoomNumbers = value ? this.roomNumbers.filter(roomNumber =>
      roomNumber.number.toLowerCase().includes(value.toLowerCase())) : this.roomNumbers.slice()
  }

  onOpenRoomNumber(open: boolean): void {
    if (open) {
      this.isKiosk$.subscribe(isKiosk => {
        if (!isKiosk) {
          this.searchRoomNumber.nativeElement.focus()
        }
      })
    } else {
      this.isKiosk$.subscribe(isKiosk => {
        if (!isKiosk) {
          this.searchRoomNumber.nativeElement.value = ""
        }
      })
      this.onSearchRoomNumber(undefined)
    }
  }

  onSelectHealthDepartment(department: Department): void {
    this.clearTvLocations()
    this.filterTvLocationItems(department)
    if (this.tvLocationItems.length === 1) {
      const tvLocationItem = this.tvLocationItems[0]
      const tvLocationControl = this.formGroup.get(this.tvLocationFormControlName)
      tvLocationControl.patchValue(tvLocationItem)
      tvLocationControl.updateValueAndValidity()
      tvLocationControl.markAsTouched()
    }
  }

  private filterTvLocationItems(department: Department): void {
    const roomNumber = this.formGroup.get(this.roomNbFormControlName).value
    const roomId = department && roomNumber ? this.institution.healthDepartments.find(dpt => dpt.id === department.id).rooms
      .find(r => r.number === roomNumber.number).id : null
    this.tvLocationItems = this.allTvLocations
      .filter(location => location.roomId === roomId)
      .map(location => ({
        location,
        available: this.filteredAvailableTvLocations.some(curLocation => this.sameLocations(curLocation, location)),
        sideName: this.sides.find(side => side.id === location.roomSide).name
      }))
  }

  sameLocations(location1: TvLocation, location2: TvLocation): boolean {
    if (location1 && location2) {
      return location1.roomId === location2.roomId
        && location1.departmentName === location2.departmentName
        && location1.roomSide === location2.roomSide
    } else {
      return location1 === location2
    }
  }

  onSearchHealthDepartment(value: string): void {
    this.filteredHealthDepartments = value ? this.healthDepartments.filter(healthDepartment =>
      healthDepartment.name.toLowerCase().includes(value.toLowerCase())) : this.healthDepartments.slice()
  }

  onOpenHealthDepartment(open: boolean): void {
    if (open) {
      this.isKiosk$.subscribe(isKiosk => {
        if (!isKiosk) {
          this.searchHealthDepartment.nativeElement.focus()
        }
      })
    } else {
      this.isKiosk$.subscribe(isKiosk => {
        if (!isKiosk) {
          this.searchHealthDepartment.nativeElement.value = ""
        }
      })
      this.onSearchHealthDepartment(undefined)
    }
  }

  clearHealthDepartments(): void {
    const control = this.formGroup.get(this.healthDepartmentFormControlName)
    if (control.valid) {
      control.reset()
    }
    this.healthDepartments = []
    this.filteredHealthDepartments = this.healthDepartments
  }

  clearTvLocations(): void {
    this.formGroup.get(this.tvLocationFormControlName).reset()
    this.tvLocationItems = []
  }

  onForceSelectionChanged($event): void {
    const forceSelection = $event.checked
    this.forceSelection = forceSelection
    const tvLocationControl = this.formGroup.get(this.tvLocationFormControlName)
    const tvLocationValidators = []
    if (this.required) {
      tvLocationValidators.push(Validators.required)
    }
    if (!forceSelection) {
      tvLocationValidators.push(this.onlyAvailableTvLocationValidator())
    }
    tvLocationControl.setValidators(tvLocationValidators)
    tvLocationControl.updateValueAndValidity()
    this.forceSelectionChanged.emit(this.forceSelection)
  }

  onlyAvailableTvLocationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value
      if (value) {
        return this.filteredAvailableTvLocations.some(location =>
          this.sameLocations(location, control.value.location)
        ) ? null : { notAvailable: { value: control.value } }
      } else {
        return null
      }
    }
  }

  clear(): void {
    this.clearTvLocations()
    this.clearHealthDepartments()
    this.formGroup.get(this.roomNbFormControlName).reset()
  }

  sameRoomNbs(roomNb1: RoomSelect, roomNb2: RoomSelect): boolean {
    return roomNb1 && roomNb2 && roomNb1.number === roomNb2.number
  }

  sameHealthDepartments(dep1: Department, dep2: Department): boolean {
    return dep1 && dep2 && dep1.id === dep2.id
  }

  sameTvLocationItems(location1: TvLocationItem, location2: TvLocationItem): boolean {
    return location1 && location2 && location1.location && location2.location
      && location1.location.roomId === location2.location.roomId
      && location1.location.roomSide === location2.location.roomSide
  }

  hideReasonKeyboard(): void {
    this.searchRoomNumber.nativeElement.blur()
  }
}
