import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { TvDto } from "../../interfaces/dto/tv-dto"
import { environment } from "../../../../environments/environment"
import { Tv } from "../../interfaces/commendable"
import { InvocationResult } from "../../interfaces/invocation-result"

@Injectable({
  providedIn: "root"
})
export class TvService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getTvs(iid: string, onlyAvailable: boolean = false): Observable<Tv[]> {
    const queryParams = new HttpParams()
      .set("onlyAvailable", onlyAvailable.toString())
    return this.httpClient.get(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/tvs`, { params: queryParams }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getAvailableTvs(iid: string): Observable<Tv[]> {
    return this.getTvs(iid, true)
  }

  createTv(iid: string, tv: Tv): Observable<Tv> {
    const tvDto: TvDto = {
      name: tv.name,
      side: tv.side,
      roomId: tv.roomId,
      agscomId: tv.agscomId,
      disabled: tv.disabled,
      comment: tv.comment
    }
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/tvs`, tvDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  updateTv(iid: string, tv: Tv): Observable<Tv> {
    const tvDto: TvDto = {
      name: tv.name,
      side: tv.side,
      roomId: tv.roomId,
      agscomId: tv.agscomId,
      disabled: tv.disabled,
      comment: tv.comment
    }
    return this.httpClient.put(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/tvs/${tv.id}`, tvDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
