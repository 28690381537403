import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { StripeCardElementOptions, StripeElementsOptions } from "@stripe/stripe-js"
import { StripeCardNumberComponent, StripeService } from "ngx-stripe"
import { Observable, of, throwError, zip } from "rxjs"
import { mergeMap } from "rxjs/operators"
import { StripeCustomService } from "src/app/shared/services/stripe/stripe.service"
import { Institution } from "../../shared/interfaces/institution";
import { ScreenService } from "../../shared/services/screen.service";

@Component({
  selector: "app-stripe",
  templateUrl: "./stripe.component.html",
  styleUrls: ["./stripe.component.scss"]
})
export class StripeComponent implements OnInit {

  @Input() institution: Institution

  @Input() parentForm: FormGroup

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent

  isKiosk$: Observable<boolean>

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: "300",
        fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  }

  elementsOptions: StripeElementsOptions = {
    locale: "fr",
  }

  constructor(
    private stripeService: StripeService,
    private screenService: ScreenService,
    private stripeCustomService: StripeCustomService,
  ) { }

  ngOnInit(): void {
    this.parentForm.addControl("cardName", new FormControl("", Validators.required))
    this.parentForm.updateValueAndValidity()
    this.isKiosk$ = this.screenService.isKiosk()
  }

  performPayment(stripeClientSecret: string): Observable<any> {
    return this.stripeService.confirmCardPayment(stripeClientSecret, {
      payment_method: {
        card: this.card.element,
        billing_details: {
          name: this.parentForm.get("cardName").value,
        },
      },
    }).pipe(
      mergeMap(result => {
        if (!result.error && result.paymentIntent.status === "succeeded") {
          return of(result)
        }
        return throwError("Payment failed!")
      })
    )
  }

  pay(total: string): Observable<any> {
    return this.stripeCustomService.createPaymentIntent(total, this.institution.id).pipe(
      mergeMap(paymentIntent =>
        zip(
          of(paymentIntent),
          this.createCard()
        )
      ),
      mergeMap(([paymentIntent, cardResult]) => {
        if (cardResult.error) {
          return throwError("The stripe card creation did not succeed")
        }
        return this.confirmCardPayment(paymentIntent.id, cardResult.paymentMethod.id)
      })
    )
  }

  createCard(): Observable<any> {
    return this.stripeService.createPaymentMethod({
      card: this.card.element,
      type: "card"
    })
  }

  confirmCardPayment(paymentIntentId: string, cardId: string): Observable<any> {
    return this.stripeCustomService.confirmPayment(paymentIntentId, cardId)
  }
}
