import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { InvocationResult } from "../../interfaces/invocation-result"
import { PaymentIntent } from "../../interfaces/payment-intent"

@Injectable({
  providedIn: "root"
})
export class StripeCustomService {

  constructor(
    private httpClient: HttpClient
  ) { }

  createPaymentIntent(price: string, institutionId: string): Observable<PaymentIntent> {
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/stripe/paymentIntents`,
      { price, institutionId }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  confirmPayment(paymentIntentId: string, paymentMethodId: string): Observable<any> {
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/stripe/confirmPayment`, { paymentIntentId, paymentMethodId }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
