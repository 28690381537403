import { Injectable } from "@angular/core"
import {HttpClient} from "@angular/common/http"
import {ConfigService} from "./config.service"
import {Observable} from "rxjs"
import {environment} from "../../../environments/environment"
import {map} from "rxjs/operators"
import {InvocationResult} from "../interfaces/invocation-result"
import {WifiManagerConnectionDto, WifiManagerConnectionReturnDto} from "../interfaces/dto/wifi-manager-dto"

@Injectable({
  providedIn: "root"
})
export class WifiService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService

  ) { }

  createConnectionToWifi(accessCode: string): Observable<WifiManagerConnectionReturnDto> {
    const sid = this.configService.getSessionId()
    const url = environment.cmnInstitutionApiBaseUrl +
      "/wifi-sessions/" + sid + "/connect-to-wifi"
    return this.httpClient.post(url, {accessCode}).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  connectToWifi(credentials: WifiManagerConnectionReturnDto): void {
    const body: WifiManagerConnectionDto = {
      username: credentials.username,
      password: credentials.password,
      dst: credentials.welcomeUri,
      popup: true
    }
    const resp = this.httpClient.post(environment.cmnInstitutionApiBaseUrl, body).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
