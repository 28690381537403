import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { AbstractControl } from "@angular/forms"

@Injectable({
  providedIn: "root"
})
export class KeyboardInputService {
  private inputLabelSubject: BehaviorSubject<string | null> = new BehaviorSubject(null)
  private inputTypeSubject: BehaviorSubject<string | null> = new BehaviorSubject(null)
  private inputMatKeyboardSubject: BehaviorSubject<string | null> = new BehaviorSubject(null)
  private inputTargetSubject: BehaviorSubject<AbstractControl | HTMLInputElement | null> = new BehaviorSubject(null)
  private inputCurrentValueSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

  inputLabel$: Observable<string | null> = this.inputLabelSubject.asObservable()
  inputType$: Observable<string | null> = this.inputTypeSubject.asObservable()
  setInputMatKeyboard$: Observable<string | null> = this.inputMatKeyboardSubject.asObservable()
  inputTarget$: Observable<AbstractControl | HTMLInputElement | null> = this.inputTargetSubject.asObservable()
  inputCurrentValue$: Observable<string | null> = this.inputCurrentValueSubject.asObservable()

  setInputTranslationKey(translationKey: string): void {
    this.inputLabelSubject.next(translationKey)
  }

  setInputType(type: string): void {
    this.inputTypeSubject.next(type)
  }

  setInputMatKeyboard(matKeyboard: string): void {
    this.inputMatKeyboardSubject.next(matKeyboard)
  }

  setInputTarget(target: AbstractControl | HTMLInputElement): void {
    this.inputTargetSubject.next(target)
  }

  setInputValue(value: string | null): void {
    this.inputCurrentValueSubject.next(value)
  }

  clearInput(): void {
      this.inputLabelSubject.next(null)
      this.inputTargetSubject.next(null)
      this.inputCurrentValueSubject.next(null)
  }
}
