<app-login *ngIf="!authPatient"
           (authenticateEvent)="onAuthenticated($event)"
></app-login>
<div class="humehealth subscription-transfer" *ngIf="authPatient && institution">
  <div class="humehealth__header subscription-transfer__header">
    <app-customer-logo></app-customer-logo>
    <button type="button"
            class="humehealth__button-secondary humehealth__header__action"
            (click)='onToggleSidebar()'>
      <img src="assets/images/menu-icon.svg" alt="menu">
    </button>
  </div>

  <div class="subscription-transfer__content">
    <ng-container *ngIf="!complete">
      <div class="humehealth__title">{{ 'ArrivalRoom' | translate }}</div>
      <app-tv-select [institution]="institution"
                     [formGroup]="formGroup"
                     [tvFormControlName]="'tvSelect'"
                     [healthDepartmentFormControlName]="'healthDepartment'"
                     [roomNbFormControlName]="'roomNumber'"
                     (next)="doTransfer()"
      ></app-tv-select>
    </ng-container>
    <ng-container *ngIf="complete">
      <app-order-complete></app-order-complete>
    </ng-container>
  </div>

  <app-subscription-sidebar class="subscription-transfer__sidebar"
                            [sidebarOpened]="sideBarOpen"
                            [subscriptionOrder]="subscriptionOrder"
                            [subscriptionActionType]="action"
                            [institution]="institution"
                            (toggle)="onToggleSidebar()"
  ></app-subscription-sidebar>
</div>
