<mat-dialog-content class="kiosk-error-dialog__content" [ngSwitch]="state">
  <ng-container *ngSwitchCase="PrinterState.OFFLINE">
    <mat-icon>offline_bolt</mat-icon>
    <p>{{ 'PRINTER_INFO.OFFLINE' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.PAPER_END">
    <mat-icon>build</mat-icon>
    <p>{{ 'PRINTER_INFO.OUT_OF_PAPER' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.COVER_OPENED">
    <mat-icon>lock_open</mat-icon>
    <p>{{ 'PRINTER_INFO.CLOSE_COVER' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.OK">
    <mat-icon>check_circle</mat-icon>
    <p>{{ 'PRINTER_INFO.OK' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon>error</mat-icon>
    <p>{{ 'PRINTER_INFO.OUT_OF_ORDER' | translate }}</p>
    <p>{{ 'RETRY_OR_CONTACT_SUPPORT' | translate }}</p>
  </ng-container>
</mat-dialog-content>
