import { CommonModule } from "@angular/common"
import { HttpClient } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { CancelComponent } from "./components/cancel-button/cancel-button.component"
import { HeadsetSidebarComponent } from "./components/headset-sidebar/headset-sidebar.component"
import { MobileMenuComponent } from "./components/mobile-menu/mobile-menu.component"
import { SubscriptionSidebarComponent } from "./components/subscription-sidebar/subscription-sidebar.component"
import { ConfirmationModalComponent } from "./modal/confirmation-modal/confirmation-modal.component"
import { LoaderModule } from "./utilities/loader/loader.module"
import { CustomerLogoComponent } from "./components/customer-logo/customer-logo.component";
import { KioskErrorComponent } from "./components/kiosk-error/Kiosk-error.component";
import { KioskErrorDialogComponent } from "./components/kiosk-error/kiosk-error-dialog/kiosk-error-dialog.component";
import { PaymentDialogComponent } from "./components/payment-dialog/payment-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    CancelComponent,
    MobileMenuComponent,
    SubscriptionSidebarComponent,
    HeadsetSidebarComponent,
    ConfirmationModalComponent,
    CustomerLogoComponent,
    KioskErrorDialogComponent,
    KioskErrorComponent,
    PaymentDialogComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, defaultLanguage: "fr"
    }),
    MatDialogModule,
    MatIconModule
  ],
  exports: [
    LoaderModule,
    CancelComponent,
    MobileMenuComponent,
    SubscriptionSidebarComponent,
    HeadsetSidebarComponent,
    CustomerLogoComponent,
    KioskErrorDialogComponent,
    KioskErrorComponent,
  ]
})
export class SharedModule { }
