import { Component, EventEmitter, Input, Output } from "@angular/core"
import { TvPackage, WifiPackage, PackagePrice } from "src/app/shared/interfaces/package"
import { environment } from "src/environments/environment"
import { CreateUserSubscriptionOrderOpen } from "../../shared/interfaces/create-user-subscription-order"

type Package = WifiPackage | TvPackage

@Component({
  selector: "app-package-select",
  templateUrl: "./package-select.component.html",
  styleUrls: ["./package-select.component.scss"]
})

export class PackageSelectComponent {

  @Input()
  createSubscriptionOrder: CreateUserSubscriptionOrderOpen

  private _packages: Package[]
  packagesToDisplay: Package[] = []

  @Input()
  set packages(packagesToSet: Package[]) {
    if (this.createSubscriptionOrder.tv) {
      packagesToSet = packagesToSet.filter(it => it.enabled)
      this._packages = packagesToSet as TvPackage[]
    } else if (this.createSubscriptionOrder.wifi) {
      this._packages = packagesToSet as WifiPackage[]
    }
    this.packagesToDisplay = this._packages.sort((p1, p2) => {
      if (p1.id === p2.id) { return 0 }
      else if (p1.recommended && p2.recommended) { return 0 }
      else if (p1.recommended) { return -1 }
      else if (p2.recommended) { return 1 }
      else if (!p1.prices.length) { return 1 }
      else if (!p2.prices.length) { return -1 }
      return p1.prices[0].value - p2.prices[0].value
    })
  }

  get packages(): Package[] {
    return this._packages
  }

  @Output()
  nextStep: EventEmitter<any> = new EventEmitter()


  constructor() { }

  selectPackage(PackageId): void {
    if (this.createSubscriptionOrder.tv) {
      this.createSubscriptionOrder.tv.channelPackageId = PackageId
    } else if (this.createSubscriptionOrder.wifi) {
      this.createSubscriptionOrder.wifi.wifiPackageId = PackageId
    }
    this.nextStep.emit({})
  }

  buildImageUrl(anyPackage: Package): string {
    return `${environment.apiBaseUrl}/public/medias/files/${anyPackage.mediaId}`
  }

  sortPricesByPeriodInDaysAsc(prices: PackagePrice[]): PackagePrice[] {
    return prices.sort((p1, p2) => p1.periodInDays - p2.periodInDays)
  }
}
