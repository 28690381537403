import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { InvocationResult } from "../../interfaces/invocation-result"
import { Payment } from "../../interfaces/payment"
import { ConfigService } from "../config.service"

@Injectable({
  providedIn: "root"
})
export class PaymentService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    // do nothing
  }

  getPayment(id: string): Observable<Payment> {
    const institutionId = this.configService.getInstitutionId()
    return this.httpClient.get(`${environment.cmnInstitutionApiBaseUrl}/institutions/${institutionId}/payments/${id}`).pipe(
      map((res: InvocationResult) => {
        return res.data as Payment
      })
    )
  }
}
