<div class="humehealth app-subscription">
  <div class="humehealth__header app-subscription__header">
    <div class="subscribe-left">
      <div class="brand">
        <a href="javascript:void(0)"><app-customer-logo></app-customer-logo></a>
      </div>

      <div class="subscrib-hello" *ngIf="subscriptionActionType == 'extend'">
        <h1>Bonjour,<span>M/Mme {{lastName}}</span></h1>
      </div>
    </div>

    <div class="subscribe-right">
      <div class="page-back">
        <button type="button" class="btn btn-outline-success" (click)="openConfirmDialog()">
          {{ 'Home' | translate }}
        </button>
      </div>
      <div class="subscribe-menu" (click)='onToggleSidebar()'>
        <div class="menu-bar">
          <img src="assets/images/menu-icon.svg" alt="menu">
          <p>{{subscriptionOrder.subscriptionPayment.price}} €</p>
        </div>
      </div>
    </div>
  </div>

  <form role="form" class="humehealth__content app-subscription__content">
    <app-stepper
      [steps]="steps"
      [secondary]="subscriptionActionType == 'transfer'"
      [selectedStep]="selectedStep"
      (stepChange)="goToStep($event)"
    ></app-stepper>
    <div *ngIf="institution" class="subscription-main">
      <ng-container *ngIf="selectedStep === FROM_LOCATION_STEP">
        <app-tv-select *ngIf="subscriptionType == 'tv'"
                       [institution]="institution"
                       [formGroup]="formGroup"
                       [tvFormControlName]="'tvSelect'"
                       [healthDepartmentFormControlName]="'healthDepartment'"
                       [roomNbFormControlName]="'roomNumber'"
                       (next)="goToNextStep()"
        ></app-tv-select>
        <app-terms-of-service *ngIf="subscriptionType == 'wifi'"
                              (nextStep)="goToNextStep()"
        ></app-terms-of-service>
      </ng-container>

      <ng-container *ngIf="selectedStep === TO_LOCATION_STEP && subscriptionActionType == 'transfer'">
        <app-tv-select *ngIf="subscriptionType == 'tv'"
                       [institution]="institution"
                       [formGroup]="formGroup"
                       [tvFormControlName]="'tvSelect'"
                       [healthDepartmentFormControlName]="'healthDepartment'"
                       [roomNbFormControlName]="'roomNumber'"
                       (next)="goToNextStep()"
        ></app-tv-select>
      </ng-container>

      <ng-container *ngIf="selectedStep === CHOOSE_TV_PACKAGE_STEP && !displayPeriodOrderStep">
        <app-package-select *ngIf="subscriptionType == 'tv'"
                            [createSubscriptionOrder]='subscriptionOrder'
                            [packages]='institution.channelPackages'
                            (nextStep)="showPeriodOrderStep()"
        ></app-package-select>
        <app-package-select *ngIf="subscriptionType == 'wifi'"
                            [createSubscriptionOrder]='subscriptionOrder'
                            [packages]='institution.wifiPackages'
                            (nextStep)="showPeriodOrderStep()"
        ></app-package-select>
      </ng-container>

      <ng-container *ngIf="selectedStep === CHOOSE_TV_PACKAGE_STEP && displayPeriodOrderStep">
        <app-period-select *ngIf="subscriptionOrder"
                           [order]="subscriptionOrder"
                           (nextStep)="goToNextStep()"
        ></app-period-select>
      </ng-container>
      <ng-container *ngIf="selectedStep === VALIDATE_ORDER_STEP && validateStep === PAY_VALIDATE_STEP && !useKioskApi">
        <app-order-validate-stripe [createUserSubscriptionOrderOpen]="subscriptionOrder"
                                   [institution]="institution"
                                   (createdSubscriptions)="setSubscription($event)"
                                   (nextStep)="goToNextValidateStep()"
        ></app-order-validate-stripe>
      </ng-container>

      <ng-container *ngIf="selectedStep === VALIDATE_ORDER_STEP && validateStep === PAY_VALIDATE_STEP && useKioskApi">
        <app-order-validate-kiosk [createUserSubscriptionOrderOpen]="subscriptionOrder"
                                  (createdSubscriptions)="setSubscription($event)" [institution]="institution"
                                  (nextStep)="goToNextValidateStep()"
        ></app-order-validate-kiosk>
      </ng-container>

      <ng-container *ngIf="selectedStep === VALIDATE_ORDER_STEP && validateStep === EMAIL_VALIDATE_STEP">
        <app-send-invoice *ngIf="subscriptionsType.tv"
                          [tvSubscription]="subscriptionsType.tv"
                          (nextStep)="goToNextValidateStep()"
        ></app-send-invoice>
      </ng-container>

      <ng-container *ngIf="selectedStep === VALIDATE_ORDER_STEP &&
                    validateStep === COMPLETE_VALIDATE_STEP &&
                    subscriptionsType.wifi || subscriptionsType.tv">
        <app-wifi-access-code *ngIf="subscriptionsType.wifi"
                              [email]="subscriptionsType.wifi.email"
                              [accessCode]="subscriptionsType.wifi.accessCode"
                              [passWord]="subscriptionsType.wifi.wifiPassWord"
        ></app-wifi-access-code>
        <app-order-complete *ngIf="subscriptionsType.tv">
        </app-order-complete>
      </ng-container>
    </div>
  </form>
  <app-subscription-sidebar class="app-subscription__sidebar"
                            [sidebarOpened]="rightSideBarOpen"
                            [subscriptionOrder]="subscriptionOrder"
                            [subscriptionActionType]="subscriptionActionType"
                            [institution]="institution"
                            (toggle)="onToggleSidebar()"
  ></app-subscription-sidebar>
</div>
