import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { MatStepper } from "@angular/material/stepper"
import { Router } from "@angular/router"
import { Observable, of, Subscription, zip } from "rxjs"
import { mergeMap, tap } from "rxjs/operators"
import { SubscriptionActionType } from "src/app/shared/constants/enum"
import { AuthPatient } from "src/app/shared/interfaces/auth-patient"
import {
  SubscriptionUser,
  CreateUserSubscriptionOrderExtend,
  CreateUserSubscriptionOrderOpen,
  SubscriptionPayment,
  CreateUserSubscriptionTvOrderOpen,
  CreateUserSubscriptionWifiOrderOpen
} from "src/app/shared/interfaces/create-user-subscription-order"
import { Department } from "src/app/shared/interfaces/department"
import { Institution } from "src/app/shared/interfaces/institution"
import { PaymentPoint } from "src/app/shared/interfaces/payment"
import { Room } from "src/app/shared/interfaces/room"
import { Tv } from "src/app/shared/interfaces/commendable"
import { TvSubscription, WifiSubscription } from "src/app/shared/interfaces/subscription"
import {
  UserSubscriptionOrder,
  UserSubscriptionPublicDeviceOrder
} from "src/app/shared/interfaces/user-subscription-order"
import { InstitutionService } from "src/app/shared/services/institution.service"
import { KioskApiService } from "src/app/shared/services/kiosk/kiosk-api.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import { LoaderService } from "src/app/shared/utilities/loader/loader.service"
import { KioskComponent } from "../kiosk/kiosk.component"
import { StripeComponent } from "../stripe/stripe.component"
import { UserSubscriptionStripeOrderDto } from "../../shared/interfaces/dto/user-subscription-dto"

@Component({
  selector: "app-subscription-extend",
  templateUrl: "./subscription-extend.component.html",
  styleUrls: ["./subscription-extend.component.scss"]
})
export class SubscriptionExtendComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  extendForm: FormGroup

  action = SubscriptionActionType.EXTEND
  complete = false
  sideBarOpen = false
  useKioskApi = false

  institution: Institution
  departments: Department[]

  start: Date
  authPatient: AuthPatient
  subscriptionOrder = {} as CreateUserSubscriptionOrderOpen
  userSubscriptions: TvSubscription | WifiSubscription

  @ViewChild("stepper")
  private transferStepper: MatStepper

  @ViewChild(StripeComponent)
  stripe: StripeComponent
  @ViewChild(KioskComponent)
  kiosk: KioskComponent

  constructor(
    private institutionService: InstitutionService,
    private subscriptionService: SubscriptionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private kioskApiService: KioskApiService,
    private loaderService: LoaderService,
  ) { }


  ngOnInit(): void {
    this.extendForm = this.formBuilder.group({})
    this.subscriptionOrder.user = {} as SubscriptionUser
    this.subscriptionOrder.tv = {} as CreateUserSubscriptionTvOrderOpen
    this.subscriptionOrder.subscriptionPayment = {} as SubscriptionPayment
    this.subscriptionOrder.subscriptionPayment.price = "--,--"
    this.subscriptions.add(this.kioskApiService.started().subscribe(kioskApiPresent => {
      this.useKioskApi = kioskApiPresent
    }))
    this.subscriptions.add(
      this.institutionService.getInstitutionWithNestedResources().pipe(
        tap(institution => {
          this.institution = institution
          this.departments = institution.healthDepartments
        }),
        mergeMap(_ => this.kioskApiService.started())
      ).subscribe(kioskApiPresent => {
        this.useKioskApi = kioskApiPresent
      })
    )
  }

  onAuthenticated(authPatient: AuthPatient): void {
    if (!authPatient.canExtend) {
      this.router.navigate(["/error", { errorMessage: "CannotExtend" }])
    }
    const department = this.getDepartment(authPatient.subscription.roomId)
    const room = this.getRoom(authPatient.subscription.roomId)
    const tv = this.getTv(authPatient.subscription.tvId)
    this.subscriptionOrder.tv.fromLocation = {
      roomId: room.id,
      roomNumber: room.number,
      roomSide: tv.side,
      departmentName: department.name
    }
    this.authPatient = authPatient
    this.start = new Date(authPatient.subscription.end)
  }

  createOrderSubscription(): Observable<UserSubscriptionOrder> {
    const userOrderSubscription: CreateUserSubscriptionOrderExtend = {
      tv: {
        subscriptionId: this.authPatient.subscription.id,
        periodInDays: this.subscriptionOrder.tv.periodInDays,
        channelPackageId: this.subscriptionOrder.tv.channelPackageId,
      },
      user: {
        firstName: this.authPatient.patient.firstName,
        lastName: this.authPatient.patient.lastName,
        email: this.authPatient.patient.email,
        birthdate: new Date(this.authPatient.patient.birthdate)
      },
      subscriptionPayment: {
        paymentPoint: this.useKioskApi ? PaymentPoint.PUBLIC_DEVICE : PaymentPoint.MOBILE_APPLICATION,
        price: this.subscriptionOrder.subscriptionPayment.price
      }
    }
    return this.subscriptionService.createSubscriptionExtend(userOrderSubscription)
  }

  doExtend(): void {
    if (this.useKioskApi) {
      this.subscriptions.add(
        this.createOrderSubscription().pipe(
          mergeMap(order => zip(
            this.kiosk.doPayment(
              order as UserSubscriptionPublicDeviceOrder,
              this.institution,
              this.authPatient.patient.lastName,
              this.authPatient.patient.firstName,
              this.subscriptionOrder.tv.fromLocation)
          ))
        ).subscribe(
          ([sub, _]) => {
            this.userSubscriptions = sub[0]
          },
          err => {
            console.log(err)
            this.router.navigateByUrl("error")
          }
        )
      )
    } else {
      this.loaderService.startLoaderOverride(true)
      this.subscriptions.add(
        this.createOrderSubscription().pipe(
          mergeMap(order => zip(
            of(order),
            this.stripe.performPayment((order as UserSubscriptionStripeOrderDto).stripeClientSecret)
          )),
          mergeMap(([order, _]) => this.subscriptionService.payForSubscription(order.id, {}))
        ).subscribe(
          sub => {
            this.loaderService.stopLoaderOverride()
            this.userSubscriptions = sub
          },
          err => {
            console.log(err)
            this.loaderService.stopLoaderOverride()
            this.router.navigateByUrl("error")
          }
        )
      )
    }
  }

  onToggleSidebar(): void {
    this.sideBarOpen = !this.sideBarOpen
  }

  goToPayStep(): void {
    this.goToNextStep()
    if (this.useKioskApi) {
      this.doExtend()
    }
  }

  goToNextStep(): void {
    this.transferStepper.selected.completed = true
    this.transferStepper.next()
  }

  doComplete(): void {
    this.complete = true
  }

  getDepartment(roomId: string): Department {
    return this.institution.healthDepartments.find(department => department.rooms.some(room => room.id === roomId))
  }

  getTv(tvId: string): Tv {
    return this.institution.tvs.find(tv => tv.id === tvId)
  }

  getRoom(roomId: string): Room {
    return this.getDepartment(roomId).rooms.find(room => room.id === roomId)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
