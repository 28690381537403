<div class="humehealth__content">
  <div class="humehealth__title">{{ 'wifiLoginTitle' | translate }}</div>
  <div class="humehealth__sub-title">{{ 'wifiLoginSubTitle' | translate }}</div>
  <div class="login-container">
    <div class="form-wrapper">
      <div class="form-row">
        <div class="form-group">
          <label>{{ 'wifiLoginEmailAddress' | translate }}</label>
          <p class="email">{{ email }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label>{{ 'wifiLoginAccessCode' | translate }}</label>
          <p class="password">{{ accessCode }}</p>
        </div>
      </div>
      <div class="form-row">
        <ng-container *ngIf="passWord != null">
          <div class="button-next">
            <button type="submit" class="btn btn-primary connect-button" (click)="goToWifiConnection()">{{'ToConnect' | translate}}</button>
          </div>
        </ng-container>
        <ng-container *ngIf="passWord == null">
          {{'TryToConnect' | translate}}
        </ng-container>
      </div>
    </div>
  </div>
</div>
