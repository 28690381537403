import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { Observable, Subscription } from "rxjs"
import { catchError, mergeMap, tap } from "rxjs/operators"
import { CentsToEurosPipe } from "src/app/shared/cents-to-euros/cents-to-euros.pipe"
import { CreateUserSubscriptionOrderOpen } from "src/app/shared/interfaces/create-user-subscription-order"
import { Institution } from "src/app/shared/interfaces/institution"
import { PaymentPoint } from "src/app/shared/interfaces/payment"
import { Subscriptions, TvSubscription, WifiSubscription } from "src/app/shared/interfaces/subscription"
import { ScreenService } from "src/app/shared/services/screen.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import { LoaderService } from "src/app/shared/utilities/loader/loader.service"
import { KioskComponent } from "../kiosk/kiosk.component"
import { ConfigService } from "../../shared/services/config.service"
import { UserSubscriptionPublicDeviceOrder } from "../../shared/interfaces/user-subscription-order"
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog"
import { PaymentDialogComponent } from "../../shared/components/payment-dialog/payment-dialog.component"


@Component({
  selector: "app-order-validate-kiosk",
  templateUrl: "./order-validate-kiosk.component.html",
  styleUrls: ["./order-validate-kiosk.component.css"],
  providers: [CentsToEurosPipe]
})
export class OrderValidateKioskComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  isKiosk$: Observable<boolean>

  @Input()
  createUserSubscriptionOrderOpen: CreateUserSubscriptionOrderOpen

  @Input()
  institution: Institution

  @Output()
  createdSubscriptions: EventEmitter<Subscriptions> = new EventEmitter()

  @Output()
  nextStep: EventEmitter<any> = new EventEmitter()

  @ViewChild(KioskComponent)
  kiosk: KioskComponent

  detailForm: FormGroup
  paymentInProgress = false

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private screenService: ScreenService,
    private configService: ConfigService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.isKiosk$ = this.screenService.isKiosk()
  }

  initForm(): void {
    let form: { [key: string]: any }
    form = {
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      validationMail: [false]
    }

    if (this.institution.manageBirthdate) { form.birthdate = ["", Validators.required] }
    if (this.institution.manageEmail || this.createUserSubscriptionOrderOpen.wifi) {
      form.email = ["", (this.institution.mandatoryEmailForWebapp || this.createUserSubscriptionOrderOpen.wifi) ?
        Validators.required : undefined]
    }
    if (this.institution.managePhone) { form.phone = ["", Validators.required] }
    if (this.institution.manageTutor) { form.tutorName = [""] }
    this.detailForm = this.formBuilder.group(form)
  }

  createOrderSubscription(): Observable<UserSubscriptionPublicDeviceOrder> {

    const userOrderSubscription: CreateUserSubscriptionOrderOpen = {
      tv: this.createUserSubscriptionOrderOpen.tv,
      wifi: this.createUserSubscriptionOrderOpen.wifi,
      user: {
        firstName: this.detailForm.value.firstName,
        lastName: this.detailForm.value.lastName,
        email: this.detailForm.value.email,
        phoneNumber: this.detailForm.value.phone,
        tutorFullName: this.detailForm.value.tutorName,
        birthdate: this.detailForm.value.birthdate,
        sendEmail: this.detailForm.value.validationMail
      },
      subscriptionPayment: {
        price: this.createUserSubscriptionOrderOpen.subscriptionPayment.price,
        paymentPoint: PaymentPoint.PUBLIC_DEVICE
      }
    }
    if (userOrderSubscription.wifi) {
      userOrderSubscription.wifi.sessionId = this.configService.getSessionId()
    }
    return this.subscriptionService.createSubscriptionOpen(userOrderSubscription) as Observable<UserSubscriptionPublicDeviceOrder>
  }

  doPayment(): void {
    this.paymentInProgress = true
    this.subscription.add(
      this.createOrderSubscription().pipe(
        mergeMap(order => {
          return this.kioskDoPayment(order)
        })
      ).subscribe(
        ([sub, _]) => {
          this.loaderService.stopLoaderOverride()
          const emitDatas = {
            wifi: this.createUserSubscriptionOrderOpen.wifi ? sub : null,
            tv: this.createUserSubscriptionOrderOpen.tv ? sub : null
          } as Subscriptions

          if (emitDatas.wifi !== null) {
            emitDatas.wifi.email = this.detailForm.value.email
          }
          this.createdSubscriptions.emit(emitDatas)
          this.nextStep.emit({})
        },
        err => {
          console.log(err)
          this.loaderService.stopLoaderOverride()
          this.router.navigateByUrl("error")
        }
      )
    )
  }

  kioskDoPayment(order: UserSubscriptionPublicDeviceOrder): Observable<[TvSubscription | WifiSubscription, boolean]> {
    const dialogRef = this.openPaymentDialog()
    return this.kiosk.doPayment(
      order,
      this.institution,
      this.detailForm.value.lastName,
      this.detailForm.value.firstName,
      this.createUserSubscriptionOrderOpen.tv.fromLocation
    ).pipe(
      tap(_ => { this.closePaymentDialog(dialogRef) } ),
      catchError(err => {
        this.closePaymentDialog(dialogRef)
        throw err
      })
    )
  }

  openPaymentDialog(): MatDialogRef<PaymentDialogComponent> {
    const config = {
      disableClose: true,
      width: "calc(100vw - 200px)",
      height: "calc(100vh - 200px)",
      panelClass: "payment-dialog",
    } as MatDialogConfig
    return this.matDialog.open(PaymentDialogComponent, config)
  }

  closePaymentDialog(dialog: MatDialogRef<PaymentDialogComponent>): void {
    setTimeout(_ => dialog.close(), 3000)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
