export const Subscription: any = [
    { id: 1, name: "new" },
    { id: 2, name: "extend" },
    { id: 3, name: "transfer" }
]

export enum SubscriptionActionType {
    EXTEND = "extend",
    OPEN = "open",
    TRANSFER = "transfer"
}

export enum SubscriptionType {
  WIFI = "wifi",
  TV = "tv"
}
