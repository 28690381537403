import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { ErrorComponent } from "./components/error/error.component"
import { HeadsetOrderComponent } from "./components/headset-order/headset-order.component"
import { HomeComponent } from "./components/home/home.component"
import { IdleComponent } from "./components/idle/idle.component"
import { IndexComponent } from "./components/index/index.component"
import { SubscriptionExtendComponent } from "./components/subscription-extend/subscription-extend.component"
import { SubscriptionTransferComponent } from "./components/subscription-transfer/subscription-transfer.component"
import { SubscriptionComponent } from "./components/subscription/subscription.component"
import { ApiAuthGuard } from "./shared/guards/apiAuth.guard"
import { WifiAccessCodeComponent } from "./components/wifi-access-code/wifi-access-code.component"
import { WifiConnectionComponent } from "./components/wifi-connection/wifi-connection.component"
import { InstitutionResolver } from "./shared/resolvers/institution/institution-resolver.service"

const routes: Routes = [
  {
    path: "",
    redirectTo: "index",
    pathMatch: "full"
  },
  {
    path: "home/:sid",
    canActivate: [ApiAuthGuard],
    component: HomeComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "home",
    canActivate: [ApiAuthGuard],
    component: HomeComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "idle",
    canActivate: [ApiAuthGuard],
    component: IdleComponent
  },
  {
    path: "wifi-access-code",
    canActivate: [ApiAuthGuard],
    component: WifiAccessCodeComponent
  },
  {
    path: "wifi-connection",
    canActivate: [ApiAuthGuard],
    component: WifiConnectionComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "index",
    canActivate: [ApiAuthGuard],
    component: IndexComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "subscription/:subscription_type/:subscription_action_type",
    canActivate: [ApiAuthGuard],
    component: SubscriptionComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "extend",
    canActivate: [ApiAuthGuard],
    component: SubscriptionExtendComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "transfer",
    canActivate: [ApiAuthGuard],
    component: SubscriptionTransferComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "headset",
    canActivate: [ApiAuthGuard],
    component: HeadsetOrderComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "error",
    canActivate: [ApiAuthGuard],
    component: ErrorComponent,
    resolve: {
      institution: InstitutionResolver
    },
  },
  {
    path: "**",
    redirectTo: "index"
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [ApiAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
