import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { Observable, Subscription } from "rxjs"
import { AuthPatient } from "src/app/shared/interfaces/auth-patient"
import { PatientService } from "src/app/shared/services/patient/patient.service"
import { ScreenService } from "src/app/shared/services/screen.service"
import { SubscriptionActionType, SubscriptionType } from "../../shared/constants/enum"

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  kiosk$: Observable<boolean>

  loginForm: FormGroup

  routerLink = "/subscription/" + SubscriptionType.TV + "/" + SubscriptionActionType.OPEN

  @Output() authenticateEvent: EventEmitter<AuthPatient> = new EventEmitter()

  constructor(
    private patientService: PatientService,
    private screenService: ScreenService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      birthdate: [null, Validators.required],
      lastName: [null, Validators.required],
      roomNumber: [null, Validators.required],
    })
    this.kiosk$ = this.screenService.isKiosk()
  }

  login(): void {
    this.subscriptions.add(
      this.patientService.authenticatePatient(
        this.loginForm.value.lastName,
        this.loginForm.value.roomNumber,
        this.loginForm.value.birthdate
      ).subscribe(
        authPatient => this.authenticateEvent.emit(authPatient),
        _ => this.router.navigate(["/error"])
      )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
