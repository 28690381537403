import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Observable, of, Subscription } from "rxjs"
import { map, mergeMap } from "rxjs/operators"
import { Email } from "src/app/shared/interfaces/email"
import { Payment } from "src/app/shared/interfaces/payment"
import { TvSubscription } from "src/app/shared/interfaces/subscription"
import { EmailService } from "src/app/shared/services/email/email.service"
import { MediaService } from "src/app/shared/services/media/media.service"
import { PaymentService } from "src/app/shared/services/payment/payment.service"
import { ScreenService } from "src/app/shared/services/screen.service"

@Component({
  selector: "app-send-invoice",
  templateUrl: "./send-invoice.component.html",
  styleUrls: ["./send-invoice.component.scss"]
})
export class SendInvoiceComponent implements OnInit, OnDestroy {

  kiosk$: Observable<boolean>

  subscription: Subscription = new Subscription()

  emailForm: FormGroup

  @Input() tvSubscription: TvSubscription
  @Input() paymentId: string

  @Output() nextStep: EventEmitter<any> = new EventEmitter()

  payment: Payment

  constructor(
    private emailService: EmailService,
    private mediaService: MediaService,
    private paymentService: PaymentService,
    private screenService: ScreenService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    })
    if (!this.paymentId) {
      this.paymentId = this.tvSubscription.paymentIds[0]
    }
    this.subscription.add(
      this.paymentService.getPayment(this.paymentId).subscribe(payment => this.payment = payment)
    )
    this.kiosk$ = this.screenService.isKiosk()
  }

  sendEmail(): void {
    this.subscription.add(
      of(this.payment).pipe(
        mergeMap(payment => {
          if (payment.invoiceMediaId != null) {
            return this.mediaService.getMedia(this.payment.invoiceMediaId).pipe(
              map(mediaDto => mediaDto.url)
            )
          } else {
            return of(payment.invoiceUrl)
          }
        }),
        mergeMap(downloadLink => {
          return this.emailService.sendEmail({
            source: "no-reply@humecloud.com",
            destination: this.emailForm.value.email,
            templateName: "hhe-invoice-download",
            templateData: {
              downloadLink
            }
          } as Email)
        })
      ).subscribe(
        _ => this.goToCompleteOrder(),
        _ => console.log("error")
      )
    )
  }

  goToCompleteOrder(): void {
    this.nextStep.emit({})
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
