<div class="payment-wrapper">
  <div class="payment-inner">
    <div class="payment-form">
      <div class="main-title">
        <p>{{ 'FillYourDetails' | translate }}</p>
      </div>
      <form [formGroup]="detailForm">
        <div class="form-row">
          <div class="form-group col-md-6">
            <app-custom-input label="{{'PatientName' | translate }}"
                          controlName="lastName"
                          [parentForm]="detailForm"
                          required="true">
            </app-custom-input>
          </div>
          <div class="form-group col-md-6">
            <app-custom-input label="{{'PatientFirstName' | translate }}"
                          controlName="firstName"
                          [parentForm]="detailForm"
                          required="true">
            </app-custom-input>
          </div>
          <div *ngIf="institution.manageTutor" class="form-group col-md-6">
            <app-custom-input label="{{'GuardianshipName' | translate }}"
                          controlName="tutorName"
                          [parentForm]="detailForm"
                          required="true">
            </app-custom-input>
          </div>
          <div *ngIf="this.institution.managePhone" class="form-group col-md-6">
            <app-custom-input label="{{'PatientPhone' | translate }}"
                          controlName="phone"
                          [parentForm]="detailForm"
                          required="true">
            </app-custom-input>
          </div>
          <div class="form-group" *ngIf="institution.manageEmail || createUserSubscriptionOrderOpen.wifi">
            <app-custom-input label="{{'PatientEmail' | translate }}"
                          controlName="email"
                          [parentForm]="detailForm"
                          [required]="institution.mandatoryEmailForWebapp">
            </app-custom-input>
          </div>
        </div>
        <div class="patient-form_birthdate" *ngIf="institution.manageBirthdate || createUserSubscriptionOrderOpen.tv">
          <app-date-picker [parentForm]="detailForm"
                           controlForm="birthdate"
                           label="{{ 'DateOfBirth' | translate }}"
                           required="true">
          </app-date-picker>
        </div>
        <app-stripe [parentForm]="detailForm" [institution]="institution"></app-stripe>
        <div class="button-next">
          <button type="submit" [disabled]="!detailForm.valid" class="btn btn-primary secondary validate-button"
            (click)="doPayment()">
            {{ 'ValidateAndPay' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
