<div class="order-wrppaer">
    <div class="order-content">
        <img src="assets/images/order-complete.svg" alt="order">
        <p>{{ label | translate }}</p>
    </div>
    <div class="action-button">
        <button type="button" class="btn secondary" (click)="navigateToHome()">
            {{ 'BackToHome' | translate }}
        </button>
    </div>
</div>
