<div class="error-wrapper">
  <h2 i18n>Une erreur est survenue</h2>
  <p *ngIf="errorMessage">{{ errorMessage | translate }}</p>
  <p>{{ "errorMessageSupport" | translate }}</p>
  <p class="support-phone-number" >{{ supportPhoneNumber }}</p>
  <div class="action-button">
      <button type="button" class="btn btn-outline-success secondary" (click)="navigateToHome()">
          {{ 'BackToHome' | translate }}
      </button>
  </div>
</div>
