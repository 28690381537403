import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import * as Sentry from "@sentry/angular"
import "hammerjs"
import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.production ? "prod" : "dev",
  integrations: [],
})

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
