import {Observable, timer} from "rxjs"
import {delayWhen, retryWhen, tap} from "rxjs/operators"

export function reconnectWebsocket(reconnectDelayInSeconds: number) {
  return function(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      retryWhen(errors => errors.pipe(
        tap(val => console.log("Try to reconnect to websocket", val)),
        delayWhen(_ => timer(reconnectDelayInSeconds * 1000))))
    )
  }
}
