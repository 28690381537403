import {Component, OnDestroy, OnInit} from "@angular/core"
import {FormBuilder, FormGroup, Validators} from "@angular/forms"
import {Observable, Subscription, timer} from "rxjs"
import {WifiService} from "../../shared/services/wifi.service"
import {ConfigService} from "../../shared/services/config.service"
import {BottomSheetOverviewComponent} from "../bottom-sheet-overview/bottom-sheet-overview.component"
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-wifi-connection",
  templateUrl: "./wifi-connection.component.html",
  styleUrls: ["./wifi-connection.component.css"]
})
export class WifiConnectionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  detailForm: FormGroup

  constructor(
    public translate: TranslateService,
    private bottomSheet: MatBottomSheet,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private wifiService: WifiService,
  ) { }

  ngOnInit(): void {
    this.initForm(
      this.configService.getAccessCode()
    )
  }

  initForm(accessCode: string): void {
    let form: { [key: string]: any }
    form = {
      accessCode: [accessCode, Validators.required],
    }
    this.detailForm = this.formBuilder.group(form)
    if (accessCode) {
      this.tryConnection()
    }
  }

  tryConnection(): void {
    this.subscriptions.add(
      this.wifiService.createConnectionToWifi(this.detailForm.value.accessCode).subscribe(
        (data) => {
          let text = this.translate.instant("wifiRedirect")
          if (data.error === "ERR_DEVICE_LIMIT") {
            text = this.translate.instant("wifiUnloggedRedirect")
          }
          this.bottomSheet.open(BottomSheetOverviewComponent, {data: {text}})
          setTimeout( () => {
            this.wifiService.connectToWifi(data)
          }, 5000)
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
