import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "../../../environments/environment"
import { AuthPatient } from "../interfaces/auth-patient"
import {
  CreateUserSubscriptionOrderExtend, CreateUserSubscriptionOrderOpen
} from "../interfaces/create-user-subscription-order"
import {
  CreateUserSubscriptionOrderExtendDto, CreateUserSubscriptionOrderOpenDto
} from "../interfaces/dto/create-user-subscription-order-dto"
import {UserSubscriptionPublicDeviceOrderDto, UserSubscriptionStripeOrderDto } from "../interfaces/dto/user-subscription-dto"
import { InvocationResult } from "../interfaces/invocation-result"
import { TvSubscription, WifiSubscription } from "../interfaces/subscription"
import { UserSubscriptionOrder } from "../interfaces/user-subscription-order"
import { ConfigService } from "./config.service"
import { SubscriptionPriceResourceDto } from "../interfaces/dto/subscription-price-resource-dto"
import {PatientService} from "./patient/patient.service";

@Injectable({
  providedIn: "root"
})

export class SubscriptionService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private patientService: PatientService
  ) { }

  computePrice(periodInDays: number, channelPackageId?: string, wifiPackageId?: string): Observable<string> {
    const institutionId = this.configService.getInstitutionId()
    const subPrDto: SubscriptionPriceResourceDto = { channelPackageId, wifiPackageId, periodInDays}
    return this.httpClient.post(environment.cmnInstitutionApiBaseUrl + "/institutions/" + institutionId + "/subscriptionPrice/", subPrDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  payForSubscription(usoid: string, paymentReference: any): Observable<TvSubscription | WifiSubscription> {
    const institutionId = this.configService.getInstitutionId()
    return this.httpClient.post(
      environment.cmnInstitutionApiBaseUrl + "/public/institutions/" + institutionId +
      "/userSubscriptions/" + usoid + "/pay", paymentReference).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  createSubscriptionOpen(userSubscription: CreateUserSubscriptionOrderOpen):
    Observable<UserSubscriptionStripeOrderDto | UserSubscriptionPublicDeviceOrderDto> {
    const institutionId = this.configService.getInstitutionId()
    const subDto: CreateUserSubscriptionOrderOpenDto = userSubscription
    subDto.user.birthdate = this.patientService.removeOffset(subDto.user.birthdate)
    return this.httpClient.post(environment.cmnInstitutionApiBaseUrl + "/public/institutions/" + institutionId + "/userSubscriptions", subDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  createSubscriptionExtend(userSubscription: CreateUserSubscriptionOrderExtend): Observable<UserSubscriptionOrder> {
    const institutionId = this.configService.getInstitutionId()
    const subDto: CreateUserSubscriptionOrderExtendDto = userSubscription
    return this.httpClient.post(environment.cmnInstitutionApiBaseUrl + "/public/institutions/" + institutionId + "/userSubscriptions", subDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  transferTvSubscription(authPatient: AuthPatient, tvId: string): Observable<TvSubscription> {
    const iid = this.configService.getInstitutionId()
    const transferSubscriptionDto = { tvId }
    const queryParams = new HttpParams().set("token", authPatient.token)
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/subscriptions/${authPatient.subscription.id}/userTransfer`,
      transferSubscriptionDto,
      { params: queryParams }
    ).pipe(
      map((res: InvocationResult) => res.data)
    )
  }
}
