import { Component } from "@angular/core"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { ConfirmationModalComponent } from "../../modal/confirmation-modal/confirmation-modal.component"

@Component({
  selector: "app-cancel-button",
  templateUrl: "./cancel-button.component.html",
  styleUrls: ["./cancel-button.component.scss"]
})
export class CancelComponent {

  constructor(private modal: NgbModal) { }

  openConfirmDialog() {
    this.modal.open(ConfirmationModalComponent, { centered: true, size: "lg", windowClass: "confirm-modal" })
  }
}
