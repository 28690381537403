<div class="loader-main" *ngIf="isLoading">
  <div class="loadingio-spinner">
    <div class="spinner-inner">
      <div></div>
      <div></div>
      <div>
        <div></div>
      </div>
      <div>
        <div></div>
      </div>
    </div>
  </div>
</div>
