<div class="humehealth__content" [formGroup]="parentForm" ngxStripeCardGroup [elementsOptions]="elementsOptions">
  <div class="humehealth__title">{{ 'BankDetails' | translate }}</div>

  <div class="humehealth__form-field">
    <app-custom-input label="{{'CardName' | translate }}"
                  controlName="cardName"
                  [parentForm]="parentForm">
    </app-custom-input>
  </div>

  <div class="humehealth__form-field">
    <div class="humehealth__label">{{ 'CardNumber' | translate }}</div>
    <ngx-stripe-card-number class="humehealth__input" [options]="cardOptions" required>
    </ngx-stripe-card-number>
  </div>

  <div class="humehealth__form-field">
    <div class="humehealth__label">{{ 'CardExpiration' | translate }}</div>
    <ngx-stripe-card-expiry class="humehealth__input" [options]="cardOptions" required>
    </ngx-stripe-card-expiry>
  </div>

  <div class="humehealth__form-field">
    <div class="humehealth__label">{{ 'CardCvc' | translate }}</div>
    <ngx-stripe-card-cvc class="humehealth__input" [options]="cardOptions" required>
    </ngx-stripe-card-cvc>
  </div>
</div>
