import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Params } from "@angular/router"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { TranslateService } from "@ngx-translate/core"
import { BsDropdownConfig } from "ngx-bootstrap/dropdown"
import { Subscription } from "rxjs"
import { SubscriptionActionType, SubscriptionType } from "src/app/shared/constants/enum"
import {
  SubscriptionUser, CreateUserSubscriptionOrderOpen, CreateUserSubscriptionTvOrderOpen,
  CreateUserSubscriptionWifiOrderOpen, SubscriptionPayment
} from "src/app/shared/interfaces/create-user-subscription-order"
import { Department } from "src/app/shared/interfaces/department"
import { ConfirmationModalComponent } from "src/app/shared/modal/confirmation-modal/confirmation-modal.component"
import { KioskApiService } from "src/app/shared/services/kiosk/kiosk-api.service"
import { Institution } from "../../shared/interfaces/institution"
import { TvLocation, Subscriptions } from "../../shared/interfaces/subscription"
import { InstitutionService } from "../../shared/services/institution.service"
import { Step } from "../stepper/stepper.component"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})

export class SubscriptionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()

  subscriptionActionType: SubscriptionActionType
  subscriptionType: SubscriptionType

  FROM_LOCATION_STEP = 0
  TO_LOCATION_STEP = 1
  CHOOSE_TV_PACKAGE_STEP = 1
  VALIDATE_ORDER_STEP = 2

  PAY_VALIDATE_STEP = 0
  EMAIL_VALIDATE_STEP = 1
  COMPLETE_VALIDATE_STEP = 2

  rightSideBarOpen = false

  steps: Step[] = []

  lastName = ""

  institution: Institution = null
  departments: Department[]
  displayPeriodOrderStep = false
  complete = false

  subscriptionOrder = {} as CreateUserSubscriptionOrderOpen
  subscriptionsType = {} as Subscriptions

  @Input()
  firstNotCompletedStep = 0

  @Input()
  selectedStep = 0
  validateStep = 0
  useKioskApi = false

  formGroup: FormGroup

  constructor(
    public translateService: TranslateService,
    private institutionService: InstitutionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private kioskApiService: KioskApiService,
  ) { }

  ngOnInit(): void {
    this.subscriptionOrder.user = {} as SubscriptionUser
    this.subscriptionOrder.subscriptionPayment = {} as SubscriptionPayment
    this.subscriptionOrder.subscriptionPayment.price = "--,--"
    this.subscriptionOrder.tv = {} as CreateUserSubscriptionTvOrderOpen
    this.subscriptionOrder.wifi = {} as CreateUserSubscriptionWifiOrderOpen

    this.initKioskApi()
    this.initInstitution()
    this.initSubscription()
    this.formGroup = this.formBuilder.group({
      roomNumber: ["", Validators.required],
      healthDepartment: [null, Validators.required],
      tvSelect: [null, Validators.required],
    })

  }

  initKioskApi(): void {
    this.subscriptions.add(
      this.kioskApiService.started().subscribe(kioskApiPresent => {
        this.useKioskApi = kioskApiPresent
      })
    )
  }

  initInstitution(): void {
    this.subscriptions.add(
      this.institutionService.getInstitutionWithNestedResources().subscribe(institution => {
        this.institution = institution
        this.departments = institution.healthDepartments
      })
    )
  }

  initSubscription(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params: Params) => {
        if (params.subscription_type) {
          this.subscriptionType = params.subscription_type
          this.subscriptionActionType = params.subscription_action_type

          if (this.subscriptionType === SubscriptionType.TV) {
            this.subscriptionOrder.wifi = null
            this.initTvSubscription()
          } else if (this.subscriptionType === SubscriptionType.WIFI) {
            this.subscriptionOrder.tv = null
            this.setupWifiOpenSteps()
          }
        }
      })
    )
  }

  initTvSubscription(): void {
    if (this.subscriptionActionType === SubscriptionActionType.EXTEND) {
      this.setupTvOpenOrExtendSteps()
    } else if (this.subscriptionActionType === SubscriptionActionType.TRANSFER) {
      this.setupTvTranferSteps()
    } else {
      this.setupTvOpenOrExtendSteps()
    }
  }

  setupWifiOpenSteps(): void {
    this.steps = [
      {
        name: this.translateService.instant("TermsOfService"),
        enabled: false, completed: false
      } as Step,
      {
        name: this.translateService.instant("ChooseYourWifiBouquet"),
        enabled: false, completed: false
      } as Step,
      {
        name: this.translateService.instant("ValidateYourOrder"),
        enabled: false, completed: false
      } as Step
    ]
    this.goToStep(this.FROM_LOCATION_STEP)
  }

  setupTvTranferSteps(): void {
    this.steps = [
      {
        name: this.translateService.instant("DepartureRoom"),
        enabled: true, completed: false
      } as Step,
      {
        name: this.translateService.instant("ArrivalRoom"),
        enabled: false, completed: false
      } as Step
    ]
    this.subscriptionOrder.tv.fromLocation = {} as TvLocation
    this.subscriptionOrder.tv.toLocation = {} as TvLocation
    this.goToStep(this.FROM_LOCATION_STEP)
  }

  setupTvOpenOrExtendSteps(): void {
    this.steps = [
      {
        name: this.translateService.instant("LocateYourRoom"),
        enabled: true, completed: false
      } as Step,
      {
        name: this.translateService.instant("ChooseYourTVBouquet"),
        enabled: false, completed: false
      } as Step,
      {
        name: this.translateService.instant("ValidateYourOrder"),
        enabled: false, completed: false
      } as Step
    ]
    if (this.subscriptionOrder.tv) {
      this.subscriptionOrder.tv.fromLocation = {} as TvLocation
    }
    this.goToStep(this.FROM_LOCATION_STEP)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  openConfirmDialog(): void {
    this.modal.open(
      ConfirmationModalComponent,
      { centered: true, size: "lg", windowClass: "confirm-modal" }
    )
  }

  goToStep(stepNumber): void {
    this.selectedStep = stepNumber
  }

  goToNextStep(): void {
    if (this.selectedStep === this.FROM_LOCATION_STEP) {
      this.retrieveSelectedTvId()
    }
    this.firstNotCompletedStep++
    this.selectedStep++
  }

  retrieveSelectedTvId(): void {
    if (this.subscriptionType === "tv") {
      this.subscriptionOrder.tv.tvId = this.institution.tvs
        .find(itTv =>
          itTv.roomId === this.formGroup.value.tvSelect.roomId &&
          itTv.side === this.formGroup.value.tvSelect.side
        ).id
      this.subscriptionOrder.tv.fromLocation.roomId = this.formGroup.value.tvSelect.roomId
      this.subscriptionOrder.tv.fromLocation.roomSide = this.formGroup.value.tvSelect.side
      this.subscriptionOrder.tv.fromLocation.roomNumber = this.formGroup.value.roomNumber.number
      this.subscriptionOrder.tv.fromLocation.departmentName = this.formGroup.value.healthDepartment.name
    }
  }

  showPeriodOrderStep(): void {
    this.displayPeriodOrderStep = true
  }

  goToNextValidateStep(): void {
    this.validateStep++
  }

  setSubscription($event): void {
    if (this.subscriptionType === SubscriptionType.WIFI) {
      this.subscriptionsType.wifi = $event.wifi
    } else if (this.subscriptionType === SubscriptionType.TV) {
      this.subscriptionsType.tv = $event.tv
    }
    this.goToNextValidateStep()
  }

  onToggleSidebar(): void {
    this.rightSideBarOpen = !this.rightSideBarOpen
  }
}
