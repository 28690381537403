import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { AuthPatient } from "../../interfaces/auth-patient"
import { InvocationResult } from "../../interfaces/invocation-result"
import { ConfigService } from "../config.service"

@Injectable({
  providedIn: "root"
})
export class PatientService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) { }

  authenticatePatient(lastName: string, roomNumber: string, birthdate: Date): Observable<AuthPatient> {
    const iid = this.configService.getInstitutionId()
    const searchPatientDto = {
      lastName,
      roomNumber,
      birthdate: this.removeOffset(birthdate),
    }
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/patients/authenticate`, searchPatientDto).pipe(
      map((res: InvocationResult) => res.data)
    )
  }

  removeOffset(date: Date): Date {
    let dateObject = new Date(date)
    return date ? new Date(dateObject.getTime() - dateObject.getTimezoneOffset() * 60000) : undefined
  }
}
