import { Component, EventEmitter, Output } from "@angular/core"

@Component({
  selector: "app-terms-of-service",
  templateUrl: "./terms-of-service.component.html",
  styleUrls: ["./terms-of-service.component.scss"]
})

export class TermsOfServiceComponent {
  @Output()
  nextStep: EventEmitter<any> = new EventEmitter()

  acceptTerms = false

  constructor() { }

  onSubmit(): boolean {
    return this.acceptTerms
  }

  goNext(): void {
    this.nextStep.emit({})
  }
}
