<div class="stepper-main">
  <div [ngClass]="{ 'stepper-inner': true, 'secondary': secondary }">
    <ul [ngClass]="{ 'second-slide' : selectedStep === 1, 'third-slide' : selectedStep === 2 }">
      <li *ngFor="let step of steps; let stepIdx = index;">
        <span [ngClass]="{ 'action-arrow': true, 'arrow-left': true, 'control-disabled': (stepIdx === 0) }">
          <img src="assets/images/arrow.svg" alt="arrow">
        </span>
        <a *ngIf="step.enabled"
          href="javascript:void(0)" (click)="goToStep(stepIdx)"
           [ngClass]="{ 'step-active': selectedStep === stepIdx, 'step-done': selectedStep > stepIdx }">
          <span class="count-number" *ngIf="selectedStep <= stepIdx">{{ (stepIdx + 1) }}</span>
          <span class="icon-done" *ngIf="selectedStep > stepIdx"><img src="assets/images/check-icon.svg" alt="check"></span>
          <span class="name"> {{step.name}} </span>
        </a>
        <a *ngIf="!step.enabled"
           [ngClass]="{ 'step-active': selectedStep === stepIdx, 'step-done': selectedStep > stepIdx }">
          <span class="count-number" *ngIf="selectedStep <= stepIdx">{{ (stepIdx + 1) }}</span>
          <span class="icon-done" *ngIf="selectedStep > stepIdx"><img src="assets/images/check-icon.svg" alt="check"></span>
          <span class="name"> {{step.name}} </span>
        </a>
        <span [ngClass]="{ 'action-arrow': true, 'arrow-right': true, 'control-disabled': !step.enabled }"
              (click)="goToStep(selectedStep + 1)">
          <img src="assets/images/arrow.svg" alt="arrow">
        </span>
      </li>
    </ul>
  </div>
</div>
