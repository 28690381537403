import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { InvocationResult } from "../../interfaces/invocation-result"
import { Media } from "../../interfaces/media"

@Injectable({
  providedIn: "root"
})
export class MediaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getMedia(
    id: string
  ): Observable<Media> {
    const url = `${environment.mediaApiBaseUrl}/medias/${id}`
    return this.httpClient.get(url).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
