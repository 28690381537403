import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { InstitutionService } from "../../shared/services/institution.service"
import { Subscription } from "rxjs"

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})

export class ErrorComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()

  errorMessage: string
  supportPhoneNumber: string

  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private institutionService: InstitutionService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.institutionService.getInstitution().subscribe(institution => {
        this.supportPhoneNumber = institution?.supportPhoneNumber || "07 55 53 67 65"
      })
    )
    this.errorMessage = this.route.snapshot.paramMap.get("errorMessage")
  }

  public navigateToHome(): void {
    this.router.navigateByUrl("index")
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
