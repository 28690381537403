// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: "https://api.dev.screenlab.humelab.com",
  cmnAppApiBaseUrl: "https://api.dev.common.humelab.com",
  cmnEmailApiBaseUrl: "https://api.dev.common.humelab.com",
  cmnUserApiBaseUrl: "https://api.dev.common.humelab.com",
  mediaApiBaseUrl: "https://api.dev.screenlab.humelab.com",
//  cmnInstitutionApiBaseUrl: "http://0.0.0.0:8031",
  cmnInstitutionApiBaseUrl: "https://api.dev.humehealth.humecloud.com",
  institutionId: "39cec78d-7b9f-4348-8f7d-773cc864e479",
  customerId: "fd5afff2-e7d6-477a-aba6-e2146c1c7c55",
  keycloak: {
    url: "https://auth.dev.common.humelab.com/auth",
    realm: "scr",
    clientId: "hhe-patient-app",
    username: "hhe-patient-app@humelab.com",
    password: "g%HuCUYVHNK7zpy6"
  },
  sentry: {
    dsn: "https://2f9694da008ae96a18faa23d2a534c6c@o474392.ingest.us.sentry.io/4507102289526784",
  },
  kioskApi: {
    websocketBaseUrl: "ws://localhost:8080",
    baseUrl: "http://localhost:8080",
    user: "kiosk",
    password: "K1osk28?"
  },
  stripePublishableKey: "pk_test_51IRzHbAEsxXKA442VhGgrIK33paERsBBbaIphLPldpGKlT5uFj3DQuFjrg0AdGJoTborJ9WCt1Ga00y42N9UDU4K00Wz4oyv5Q"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
