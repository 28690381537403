import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "../../../environments/environment"
import { CONTENT_TYPE } from "../constants/content-type"
import { AuthPatient } from "../interfaces/auth-patient"
import { CreateProduct } from "../interfaces/create-product"
import { CreateProductDto } from "../interfaces/dto/create-product-dto"
import { InvocationResult } from "../interfaces/invocation-result"
import { Product } from "../interfaces/product"
import { ConfigService } from "./config.service"

@Injectable({
  providedIn: "root"
})
export class ProductService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  createProduct(authPatient: AuthPatient, createProduct: CreateProduct): Observable<Product> {
    const iid = this.configService.getInstitutionId()
    const headers: HttpHeaders = new HttpHeaders().append(
      "Accept",
      CONTENT_TYPE.SESSION_TOKEN
    )
    const queryParams = new HttpParams().set("token", authPatient.token)
    const createProductDto = createProduct as CreateProductDto
    return this.httpClient.post(`${environment.cmnInstitutionApiBaseUrl}/institutions/${iid}/products/`, createProductDto, { params: queryParams, headers }).
      pipe(map((res: InvocationResult) => res.data))
  }
}
