<div class="main-title">
  <p>{{ 'FilInTheBoxes' | translate }}</p>
</div>
<div class="duration-main-block">
  <div class="room-select">
    <div class="room-select__content">
      <div class="room-select__content__option">
        <app-tv-location-select [enableAction]="enableAction"
                                [actionLabel]="actionLabel"
                                [header]="header"
                                [forceSelection]="forceSelection"
                                [allowNotAvailableSelect]="allowNotAvailableSelect"
                                [institution]="institution"
                                [formGroup]="formGroup"
                                [tvLocations]="tvLocations"
                                [availableTvLocations]="availableTvLocations"
                                [healthDepartmentFormControlName]="healthDepartmentFormControlName"
                                [roomNbFormControlName]="roomNbFormControlName"
                                [tvLocationFormControlName]="roomSideFormControlName"
                                (forceSelectionChanged)="onForceSelectionChanged($event)"
                                (tvLocationChanged)="onTvLocationChanged($event)"
        ></app-tv-location-select>
      </div>
    </div>
    <div class="room-select__actions">
      <button type="button" class="btn btn-primary secondary arrow-right" (click)="goNext()">
        {{ 'Next' | translate }}
      </button>
    </div>
  </div>
</div>
