import { Component, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { SubscriptionActionType } from "src/app/shared/constants/enum"
import { AuthPatient } from "src/app/shared/interfaces/auth-patient"
import { Department } from "src/app/shared/interfaces/department"
import { Institution } from "src/app/shared/interfaces/institution"
import { Room } from "src/app/shared/interfaces/room"
import { Tv } from "src/app/shared/interfaces/commendable"
import { TvLocation } from "src/app/shared/interfaces/subscription"
import { InstitutionService } from "src/app/shared/services/institution.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import {
  CreateUserSubscriptionOrderOpen,
  CreateUserSubscriptionTvOrderOpen,
  SubscriptionPayment,
  SubscriptionUser
} from "../../shared/interfaces/create-user-subscription-order"
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-subscription-transfer",
  templateUrl: "./subscription-transfer.component.html",
  styleUrls: ["./subscription-transfer.component.scss"]
})

export class SubscriptionTransferComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  action = SubscriptionActionType.TRANSFER
  complete = false
  sideBarOpen = false

  institution: Institution
  departments: Department[]
  tvs: Tv[]

  authPatient: AuthPatient
  subscriptionOrder = {} as CreateUserSubscriptionOrderOpen

  formGroup: FormGroup

  constructor(
    private institutionService: InstitutionService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.subscriptionOrder.wifi = null
    this.subscriptionOrder.tv = {} as CreateUserSubscriptionTvOrderOpen
    this.subscriptionOrder.tv.toLocation = {} as TvLocation
    this.subscriptionOrder.user = {} as SubscriptionUser
    this.subscriptionOrder.subscriptionPayment = {} as SubscriptionPayment
    this.subscriptions.add(
      this.institutionService.getInstitutionWithNestedResources().subscribe(
        institution => {
          this.institution = institution
          this.departments = institution.healthDepartments
          this.tvs = institution.tvs
        }
      )
    )
    this.formGroup = this.formBuilder.group({
      roomNumber: ["", Validators.required],
      healthDepartment: [null, Validators.required],
      tvSelect: [null, Validators.required],
    })
  }

  onAuthenticated(authPatient: AuthPatient): void {
    const department = this.getDepartment(authPatient.subscription.roomId)
    const room = this.getRoom(authPatient.subscription.roomId)
    const tv = this.getTv(authPatient.subscription.tvId)
    this.subscriptionOrder.tv.fromLocation = {
      roomId: room.id,
      roomNumber: room.number,
      roomSide: tv.side,
      departmentName: department.name
    }
    this.authPatient = authPatient
  }

  doTransfer(): void {
    this.subscriptions.add(
      this.subscriptionService.transferTvSubscription(
        this.authPatient, this.computeTvId()
      ).subscribe(
        _ => this.complete = true,
        _ => this.router.navigate(["/error"])
      )
    )
  }

  onToggleSidebar(): void {
    this.sideBarOpen = !this.sideBarOpen
  }

  computeTvId(): string {
    return this.institution.tvs
      .find(itTv =>
        itTv.roomId === this.formGroup.value.tvSelect.roomId &&
        itTv.side === this.formGroup.value.tvSelect.side
      ).id
  }

  getDepartment(roomId: string): Department {
    return this.institution.healthDepartments.find(department => department.rooms.some(room => room.id === roomId))
  }

  getTv(tvId: string): Tv {
    return this.institution.tvs.find(tv => tv.id === tvId)
  }

  getRoom(roomId: string): Room {
    return this.getDepartment(roomId).rooms.find(room => room.id === roomId)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
