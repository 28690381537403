import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { KeycloakService } from "../services/keycloak.service"

@Injectable({
    providedIn: "root"
})
export class ApiAuthGuard implements CanActivate {

    constructor(
      private keycloakService: KeycloakService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.keycloakService.getAccessToken().pipe(
          map(accessToken => true)
        )
    }
}
