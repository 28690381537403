import { BreakpointObserver } from "@angular/cdk/layout"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { mergeMap } from "rxjs/operators"

@Injectable({
  providedIn: "root"
})
export class ScreenService {

  constructor(
    private bpo: BreakpointObserver,
  ) {
    // do nothing
  }

  isKiosk(): Observable<boolean> {
    return this.bpo.observe(["(min-width: 767px) and (orientation: portrait)"]).pipe(
      mergeMap(result => of(result.matches))
    )
  }
}
