<form class="send-invoice" [formGroup]="emailForm" (ngSubmit)="sendEmail()" *ngIf="payment">
  <div class="send-invoice__title main-title">
    <p>{{ 'Email.DoYouWantAnInvoice' | translate }}</p>
  </div>
  <div class="send-invoice__content">
    <div>
      <label class="label-green">{{ 'Email.FillYourEmail' | translate }}</label>
      <input type="email" class="form-control secondary-input" formControlName="email"
        *ngIf="kiosk$ | async; else classicEmail" matKeyboard [matKeyboard]="'keyboard_fr'">
      <ng-template #classicEmail>
        <input type="email" class="form-control secondary-input" formControlName="email">
      </ng-template>
    </div>
    <div class="action-button">
      <button type="submit" class="btn btn-secondary" [disabled]="!emailForm.valid">
        {{ 'Email.Send' | translate }}
      </button>
    </div>
    <div class="action-button">
      <button type="button" class="btn btn-primary" (click)="goToCompleteOrder()">
        {{ 'Email.Skip' | translate }}
      </button>
    </div>
  </div>
</form>
