import { Component, OnDestroy, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Observable, of, Subscription, throwError, zip } from "rxjs"
import { mergeMap, tap } from "rxjs/operators"
import { CentsToEurosPipe } from "src/app/shared/cents-to-euros/cents-to-euros.pipe"
import { Institution } from "src/app/shared/interfaces/institution"
import { TvLocation, TvSubscription, WifiSubscription } from "src/app/shared/interfaces/subscription"
import { CustomerService } from "src/app/shared/services/customer/customer.service"
import { KioskApiService } from "src/app/shared/services/kiosk/kiosk-api.service"
import { MediaService } from "src/app/shared/services/media/media.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import { Debit } from "../../shared/interfaces/debit"
import * as mustache from "mustache"
import { UserSubscriptionPublicDeviceOrder } from "../../shared/interfaces/user-subscription-order";

@Component({
  selector: "app-kiosk",
  templateUrl: "./kiosk.component.html",
  styleUrls: ["./kiosk.component.scss"],
  providers: [CentsToEurosPipe]
})
export class KioskComponent implements OnInit, OnDestroy {
  mediaUrl = ""

  ticketTemplate = `(% img center '{{{logoUrl}}}' %)

{{{ticketNb}}}

  Site : {{{site}}}
  Nom : {{{lastName}}}
  Prénom : {{{firstName}}}
  Chambre : {{{roomNumber}}}
  Côté : {{{side}}}
  Service : {{{service}}}
  Abonnement TV : {{{channelPackageName}}}
  Du : {{{startDate}}}
  Au : {{{endDate}}}
{{{amount}}}
{{{imageRightVAT}}}
{{{tvLocationVAT}}}

  Règlement : Carte Bancaire`

  message = ""

  subscription = new Subscription()

  constructor(
    private kioskApiService: KioskApiService,
    private subscriptionService: SubscriptionService,
    private centsToEurosPipe: CentsToEurosPipe,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.message = this.translateService.instant("InsertCard")
    this.subscription.add(this.customerService.getCustomer().pipe(
      mergeMap(customer => {
        return this.mediaService.getMedia(customer.logoMediaId)
      })
    ).subscribe(media => {
      this.mediaUrl = media.url
    }))
    this.subscription.add(this.kioskApiService.getPaymentInfos().subscribe(paymentInfo => {
      this.message = paymentInfo.msg
    }))
  }

  doPayment(
    order: UserSubscriptionPublicDeviceOrder,
    institution: Institution,
    lastName: string,
    firstName: string,
    tvLocation: TvLocation
  ): Observable<[TvSubscription | WifiSubscription, boolean]> {
    return this.kioskApiService.debit(order.amountInCents, order.ticketNb).pipe(
      mergeMap(debit => {
        if (!debit.successful) {
          return throwError("The payment failed.")
        } else {
          return of(debit)
        }
      }),
      mergeMap(debit => {
        return zip(
          this.subscriptionService.payForSubscription(order.id, {
            ticket: debit.ticket ? debit.ticket : "",
            ticketNb: debit.ticketNb,
            serverTransactionNb: debit.serverTransactionNb,
            bankingServerNb: debit.bankingServerNb,
            transactionDateTime: debit.transactionDateTime
          }),
          of(debit)
        )
      }),
      mergeMap(([tvSubscription, debit]) => {
        return zip(
          of(tvSubscription),
          this.kioskApiService.printBill(
            this.buildTicket(order, debit, institution, lastName, firstName, tvLocation, tvSubscription)
          )
        )
      })
    )
  }

  buildTicket(
     order: UserSubscriptionPublicDeviceOrder,
     debit: Debit,
     institution: Institution,
     lastName: string,
     firstName: string,
     tvLocation: TvLocation,
     tvSubscription: TvSubscription | WifiSubscription)
     : string {
     const templateData = {
       site: institution.name,
       lastName,
       firstName,
       roomNumber: tvLocation.roomNumber,
       side: this.translateService.instant("ROOM_SIDE." + tvLocation.roomSide),
       service: tvLocation.departmentName,
       channelPackageName: institution.channelPackages.find(cp => cp.id === order.channelPackageId).name,
       startDate: new Date(tvSubscription.start).toLocaleDateString(),
       endDate: new Date(tvSubscription.end).toLocaleDateString(),
       amount: "(% text right 'Montant total TTC : " + this.centsToEurosPipe.transform(order.amountInCents, "1.2") + "€ ' %)",
       imageRightVAT: "(% text right 'TVA Droit à l&sq;image (" + institution.imageRightsVATRate * 100 + "%) : " + this.centsToEurosPipe.transform(order.amountInCents * institution.imageRightsVATRate, "1.2") + "€ ' %)",
       tvLocationVAT: "(% text right 'TVA Location TV (" + institution.tvRentalVATRate * 100 + "%) : " + this.centsToEurosPipe.transform(order.amountInCents * institution.tvRentalVATRate, "1.2") + "€ ' %)",
       ticketNb: "(% text whiteOnBlack fontA 2x2 bold center '   Ticket n° " + order.ticketNb + "   ' %)",
       ticketDate: new Date(debit.transactionDateTime).toLocaleDateString(),
       logoUrl: this.mediaUrl
     }

     const ticket = mustache.render(this.ticketTemplate, templateData)
     return ticket
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
