import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs";
import { ScreenService } from "../../shared/services/screen.service";

@Component({
  selector: "app-order-complete",
  templateUrl: "./order-complete.component.html",
  styleUrls: ["./order-complete.component.css"]
})
export class OrderCompleteComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  @Input() label: string

  returnToHomeTimeout = null

  constructor(
    private router: Router,
    private screenService: ScreenService,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    if (!this.label) {
      this.label = "OrderTakenTv"
    } else if (this.label === "OrderTakenWifi") {
      this.label = "OrderTakenWifi"
    }
    this.subscription.add(
      this.screenService.isKiosk().subscribe(isKiosk => {
        if (isKiosk) {
          this.returnToHomeTimeout = setTimeout(_ => {
            this.navigateToHome()
          }, 20000)
        }
      })
    )
  }

  navigateToHome(): void {
    this.router.navigateByUrl("index")
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()

    if (this.returnToHomeTimeout) {
      clearTimeout(this.returnToHomeTimeout)
    }
  }
}
