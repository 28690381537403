import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { LoaderComponent } from "./loader.component"
import { LoaderService } from "./loader.service"
import { RouterModule } from "@angular/router"

@NgModule({
    declarations: [LoaderComponent],
    imports: [CommonModule, RouterModule],
    providers: [LoaderService],
    exports: [LoaderComponent],
})
export class LoaderModule { }
