import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import {Observable, of} from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "../../../environments/environment"
import { CONTENT_TYPE } from "../constants/content-type"
import {Institution, CustomerInstitutionIdDto} from "../interfaces/institution"
import { InvocationResult } from "../interfaces/invocation-result"
import { ConfigService } from "./config.service"

@Injectable({
  providedIn: "root"
})
export class InstitutionService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  getInstitutionWithNestedResources(): Observable<Institution> {
    const institutionId = this.configService.getInstitutionId()
    const headers: HttpHeaders = new HttpHeaders().append(
      "Accept",
      CONTENT_TYPE.INCLUDE_NESTED_OBJECTS
    )
    return this.httpClient.get(environment.cmnInstitutionApiBaseUrl + "/institutions/" + institutionId, { headers }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getInstitution(): Observable<Institution> {
    const institutionId = this.configService.getInstitutionId()
    return this.httpClient.get(environment.cmnInstitutionApiBaseUrl + "/institutions/" + institutionId).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getCustomerInstitutionIdFromInstitutionLinkId(institutionLinkId: string): Observable<CustomerInstitutionIdDto> {
    return this.httpClient.get(environment.cmnInstitutionApiBaseUrl + "/institution-links/" + institutionLinkId).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  setWifiSessionInstitutionId(sid: string): void {
    const iid = this.configService.getInstitutionId()
    this.httpClient.patch(
      environment.cmnInstitutionApiBaseUrl + "/wifi-sessions/" + sid, {
        update: {institutionId: iid}
      }
    ).subscribe()
  }
}
