
export interface Payment {
  id?: string,
  type: PaymentType,
  point: PaymentPoint,
  value: string,
  status: PaymentStatus,
  doneAt?: string,
  comment?: string,
  invoiceMediaId?: string,
  invoiceUrl?: string,
  refund: boolean,
  finalized: boolean,
  subscriptionId: string
}

export enum PaymentType {
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  CASH = "CASH",
  STRIPE = "STRIPE",
  OTHER = "OTHER",
  OFFERED = "OFFERED",
  TRANSFER = "TRANSFER"
}

export enum PaymentPoint {
  CALL_CENTER = "CALL_CENTER",
  PUBLIC_DEVICE = "PUBLIC_DEVICE",
  RECEPTIONIST = "RECEPTIONIST",
  WEB = "WEB",
  MOBILE_APPLICATION = "MOBILE_APPLICATION"
}

export enum PaymentStatus {
  DONE = "DONE",
  WAITING = "WAITING"
}
