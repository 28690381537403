import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common"
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import localeFr from "@angular/common/locales/fr"
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core"
import * as Sentry from "@sentry/angular"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatInputModule } from "@angular/material/input"
import { MatStepperModule } from "@angular/material/stepper"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import {Router, RouterModule} from "@angular/router"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { NgxStripeModule } from "ngx-stripe"
import { environment } from "src/environments/environment"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { ErrorComponent } from "./components/error/error.component"
import { HeadsetOrderComponent } from "./components/headset-order/headset-order.component"
import { HomeComponent } from "./components/home/home.component"
import { IdleComponent } from "./components/idle/idle.component"
import { KioskComponent } from "./components/kiosk/kiosk.component"
import { LoginComponent } from "./components/login/login.component"
import { OrderCompleteComponent } from "./components/order-complete/order-complete.component"
import { OrderValidateKioskComponent } from "./components/order-validate-kiosk/order-validate-kiosk.component"
import { OrderValidateStripeComponent } from "./components/order-validate-stripe/order-validate-stripe.component"
import { PeriodSelectComponent } from "./components/period-select/period-select.component"
import { SendInvoiceComponent } from "./components/send-invoice/send-invoice.component"
import { StepperComponent } from "./components/stepper/stepper.component"
import { StripeComponent } from "./components/stripe/stripe.component"
import { SubscriptionExtendComponent } from "./components/subscription-extend/subscription-extend.component"
import { SubscriptionTransferComponent } from "./components/subscription-transfer/subscription-transfer.component"
import { SubscriptionComponent } from "./components/subscription/subscription.component"
import { PackageSelectComponent } from "./components/package-select/package-select.component"
import { CentsToEurosPipe } from "./shared/cents-to-euros/cents-to-euros.pipe"
import { AuthInterceptor } from "./shared/interceptors/auth-interceptor.service"
import { ErrorInterceptor } from "./shared/interceptors/error.interceptor"
import { KioskApiInterceptor } from "./shared/interceptors/kiosk-api.interceptor"
import { ScopeInterceptorService } from "./shared/interceptors/scope.interceptor.service"
import { SharedModule } from "./shared/shared.module"
import { IndexComponent } from "./components/index/index.component"
import { IKeyboardLayouts, KeyboardClassKey, keyboardLayouts, MatKeyboardModule, MAT_KEYBOARD_LAYOUTS } from "angular-onscreen-material-keyboard"
import { TermsOfServiceComponent } from "./components/terms-of-service/terms-of-service.component"
import { WifiAccessCodeComponent } from "./components/wifi-access-code/wifi-access-code.component"
import { WifiConnectionComponent } from "./components/wifi-connection/wifi-connection.component"
import { BottomSheetOverviewComponent } from "./components/bottom-sheet-overview/bottom-sheet-overview.component"
import { MatListModule } from "@angular/material/list"
import { MatBottomSheetModule } from "@angular/material/bottom-sheet"
import {MatDatepickerModule} from "@angular/material/datepicker"
import { KeyboardInputComponent } from "./components/keyboard/keyboard-input.component"
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core"
import { TvSelectComponent } from "./components/tv-select/tv-select.component"
import { TvLocationSelectComponent } from "./components/tv-location-select/tv-location-select.component"
import { MatSelectModule } from "@angular/material/select"
import { MatIconModule } from "@angular/material/icon"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { CustomInputComponent } from "./components/custom-input/custom-input.component"
import { DatePickerComponent } from "./components/date-picker/date-picker.component"

registerLocaleData(localeFr)

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http)
}

const devProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: KioskApiInterceptor, multi: true },
]

const customLayouts: IKeyboardLayouts = {
  ...keyboardLayouts,
  keyboard_fr: {
    name: "Custom Keyboard - FR",
    keys: [
      [
        ["1"],
        ["2"],
        ["3"],
        ["4"],
        ["5"],
        ["6"],
        ["7"],
        ["8"],
        ["9"],
        ["0"],
        [KeyboardClassKey.Bksp]
      ],
      [
        ["a"],
        ["z"],
        ["e"],
        ["r"],
        ["t"],
        ["y"],
        ["u"],
        ["i"],
        ["o"],
        ["p"],
      ],
      [
        ["q"],
        ["s"],
        ["d"],
        ["f"],
        ["g"],
        ["h"],
        ["j"],
        ["k"],
        ["l"],
        ["m"],
        [KeyboardClassKey.Enter]
      ],
      [
        ["w"],
        ["x"],
        ["c"],
        ["v"],
        ["b"],
        ["n"],
        ["."],
        ["-"],
        ["_"],
        ["@"]
      ],
      [
        [KeyboardClassKey.Space]
      ]
    ],
    lang: ["fr"]
  },
  Numeric: {
    name: "Numeric",
    keys: [
      [
        ["1"],
        ["2"],
        ["3"]
      ],
      [
        ["4"],
        ["5"],
        ["6"]
      ],
      [
        ["7"],
        ["8"],
        ["9"]
      ],
      [
        [KeyboardClassKey.Bksp],
        ["0"],
        [KeyboardClassKey.Enter]
      ]
    ],
    lang: ["num"]
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CentsToEurosPipe,
    ErrorComponent,
    HomeComponent,
    IdleComponent,
    IndexComponent,
    KioskComponent,
    LoginComponent,
    OrderCompleteComponent,
    OrderValidateStripeComponent,
    OrderValidateKioskComponent,
    PeriodSelectComponent,
    SendInvoiceComponent,
    StepperComponent,
    StripeComponent,
    SubscriptionComponent,
    SubscriptionExtendComponent,
    SubscriptionTransferComponent,
    PackageSelectComponent,
    HeadsetOrderComponent,
    TermsOfServiceComponent,
    WifiAccessCodeComponent,
    WifiConnectionComponent,
    BottomSheetOverviewComponent,
    KeyboardInputComponent,
    TvSelectComponent,
    TvLocationSelectComponent,
    CustomInputComponent,
    DatePickerComponent,
  ],
  imports: [
    AppRoutingModule,
    MatNativeDateModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatInputModule,
    MatKeyboardModule,
    MatStepperModule,
    NgbModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, defaultLanguage: "fr"
    }),
    MatListModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    }, {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }, {
      provide: LOCALE_ID,
      useValue: "fr"
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScopeInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: customLayouts
    },
    !environment.production ? devProviders : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
