<div class="subscription-sidebar" [ngClass]="{'sidebar-open': sidebarOpened}">
  <div class="subscription-inner">
    <div class="mobile-block">
      <div class="block-inner">
        <div class="brand">
          <a href="javascript:void(0)"><app-customer-logo></app-customer-logo></a>
        </div>
        <div class="mobile-button" (click)="close()">
          <a href="javascript:void(0)"><span class="close-icon">X</span>Fermer</a>
        </div>
      </div>
    </div>
    <div class="subscribe-title subscription-sidebar__title">
      <h1>{{ headerText }}</h1>
      <p>{{ toCommandText }}</p>
    </div>
    <div class="subscribe-service">
      <div class="humehealth__label" *ngIf="isTransfer && subscriptionOrder && subscriptionOrder.tv.toLocation">
        {{ 'DepartureRoom' | translate }}
      </div>
      <ul class="subscription-sidebar__summary">
        <ng-container *ngIf="subscriptionOrder.tv">
          <li *ngIf="subscriptionOrder && subscriptionOrder.tv.fromLocation">
            <span class="service-label">{{ 'Service' | translate }}:</span>
            <p class="service-value">{{(subscriptionOrder.tv.fromLocation.departmentName ? subscriptionOrder.tv.fromLocation.departmentName : '--')}}</p>
          </li>
          <li *ngIf="subscriptionOrder && subscriptionOrder.tv.fromLocation">
            <span class="service-label">{{ 'RoomNumber' | translate }}:</span>
            <p class="service-value">{{(subscriptionOrder.tv.fromLocation.roomNumber ? subscriptionOrder.tv.fromLocation.roomNumber : '--')}}</p>
          </li>
          <li *ngIf="subscriptionOrder && subscriptionOrder.tv.fromLocation">
            <span class="service-label">{{ 'Side' | translate }}:</span>
            <p class="service-value">{{(subscriptionOrder.tv.fromLocation.roomSide ? 'ROOM_SIDE.' + subscriptionOrder.tv.fromLocation.roomSide : '--') | translate}}</p>
          </li>
        </ng-container>

        <li *ngIf="!isTransfer && subscriptionOrder && (subscriptionOrder.tv || subscriptionOrder.wifi)">

          <span *ngIf="subscriptionOrder.wifi" class="service-label">{{ 'WifiPackage' | translate }}:</span>
          <p class="service-value" *ngIf="subscriptionOrder.wifi">
            {{getPackageName(subscriptionOrder.wifi.wifiPackageId)}}
          </p>

          <span *ngIf="subscriptionOrder.tv" class="service-label">{{ 'TVPackage' | translate }}:</span>
          <p class="service-value" *ngIf="subscriptionOrder.tv">
            {{getPackageName(subscriptionOrder.tv.channelPackageId)}}
          </p>
        </li>

        <li *ngIf="!isTransfer && subscriptionOrder">
          <span class="service-label">{{ 'SubscriptionDuration' | translate }}:</span>
          <p class="service-value" *ngIf="subscriptionOrder.wifi" >
            {{ subscriptionOrder.wifi.periodInDays ? subscriptionOrder.wifi.periodInDays :  '--'}}
            {{ 'Days' | translate }}
          </p>
          <p class="service-value" *ngIf="subscriptionOrder.tv" >
            {{ subscriptionOrder.tv.periodInDays ? subscriptionOrder.tv.periodInDays :  '--'}}
            {{ 'Days' | translate }}
          </p>
        </li>
        <li *ngIf="!isTransfer">
          <span class="service-label">{{ 'SubscriptionStart' | translate }}:</span>
          <p class="service-value">{{ subscriptionStart | date : 'EEEE d MMMM  y' }}</p>
        </li>

        <li *ngIf="!isTransfer && subscriptionOrder">
          <span class="service-label">{{ 'SubscriptionEnd' | translate }}:</span>

          <ng-container *ngIf="subscriptionOrder.tv">
            <p class="service-value">
              <ng-container  *ngIf="subscriptionOrder.tv.periodInDays; then subscription_end_defined; else subscription_end_not_defined">
              </ng-container>
            </p>
            <ng-template #subscription_end_defined>
              {{ getSubscriptionEnd(subscriptionOrder.tv.periodInDays) | date : 'EEEE d MMMM  y' }}
            </ng-template>
          </ng-container>
          <ng-container *ngIf="subscriptionOrder.wifi">
            <p class="service-value">
              <ng-container  *ngIf="subscriptionOrder.wifi.periodInDays; then subscription_end_defined; else subscription_end_not_defined">
              </ng-container>
            </p>
            <ng-template #subscription_end_defined>
              {{ getSubscriptionEnd(subscriptionOrder.wifi.periodInDays) | date : 'EEEE d MMMM  y' }}
            </ng-template>
          </ng-container>
          <ng-template #subscription_end_not_defined>
            --
          </ng-template>
        </li>

      </ul>
      <ng-container *ngIf="subscriptionOrder.tv">
        <div class="humehealth__label" *ngIf="isTransfer && subscriptionOrder && subscriptionOrder.tv.toLocation">
          {{ 'ArrivalRoom' | translate }}
        </div>
        <ul class="subscription-sidebar__summary">
          <li *ngIf="isTransfer && subscriptionOrder && subscriptionOrder.tv.toLocation">
            <span class="service-label">{{ 'Service' | translate }}:</span>
            <p class="service-value">{{(subscriptionOrder.tv.toLocation.departmentName ? subscriptionOrder.tv.toLocation.departmentName : '--')}}</p>
          </li>
          <li *ngIf="isTransfer && subscriptionOrder && subscriptionOrder.tv.toLocation">
            <span class="service-label">{{ 'RoomNumber' | translate }}:</span>
            <p class="service-value">{{(subscriptionOrder.tv.toLocation.roomNumber ? subscriptionOrder.tv.toLocation.roomNumber : '--')}}</p>
          </li>
          <li *ngIf="isTransfer && subscriptionOrder && subscriptionOrder.tv.toLocation">
            <span class="service-label">{{ 'Side' | translate }}:</span>
            <p class="service-value">{{ (subscriptionOrder.tv.toLocation.roomSide ? 'ROOM_SIDE.' + subscriptionOrder.tv.toLocation.roomSide : '--') | translate}}</p>
          </li>
        </ul>
      </ng-container>
    </div>
    <div class="subscribe-total subscription-sidebar__total"
         *ngIf="!isTransfer && subscriptionOrder && subscriptionOrder.subscriptionPayment.price">
      <div class="small-title">
        <h6>{{ 'AmountIncl' | translate }}</h6>
        <p> {{ subscriptionOrder.subscriptionPayment.price }} <span class="currency">&#x20AC;</span></p>
      </div>
    </div>
  </div>
</div>
