<div class="keyboard-container" *ngIf="isVisible">
  <label class="label">
    {{ label }}
  </label>
  <input type="text"
         [placeholder]="type == 'date' ? 'dd-mm-yyyy' : ''"
         [ngModel]="inputValue" (ngModelChange)="onInputChange($event)"
         (focusout)="onClose()"
         [matKeyboard]="matKeyboard"
         #keyboardInput
         (input)="onInput($event)"
         (enterClick)="onClose()">
</div>
