<div class="form-container">
  <form class="form-wrapper" [formGroup]="detailForm">
    <div class="form-row">
      <div class="form-group col-md-6">
        <app-custom-input label="{{'PatientAccessCode' | translate }}"
                      controlName="accessCode"
                      [parentForm]="detailForm"
                      required="true">
        </app-custom-input>
      </div>
      <div class="button-next">
        <button type="submit" [disabled]="!detailForm.valid" class="btn btn-primary secondary validate-button"
                (click)="tryConnection()">
          {{ "WifiLogin" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
