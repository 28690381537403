import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { SubscriptionActionType } from "../../constants/enum"
import { Institution } from "../../interfaces/institution"
import { CreateUserSubscriptionOrderOpen } from "../../interfaces/create-user-subscription-order"
import { Subscription } from "rxjs"

@Component({
  selector: "app-subscription-sidebar",
  templateUrl: "./subscription-sidebar.component.html",
  styleUrls: ["./subscription-sidebar.component.scss"]
})
export class SubscriptionSidebarComponent implements OnDestroy, OnChanges {
  subscriptions: Subscription = new Subscription()

  toNumber: string
  toSide: string
  toCommandText: string
  isTransfer: boolean
  headerText: string
  footerText: string

  @Input()
  sidebarOpened = false
  @Input()
  institution: Institution
  @Input()
  subscriptionOrder: CreateUserSubscriptionOrderOpen
  @Input()
  subscriptionStart = new Date()
  @Input()
  subscriptionActionType: string
  @Output()
  toggle: EventEmitter<any> = new EventEmitter()

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  close(): void {
    this.toggle.emit({})
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.subscriptionActionType) {
      if (this.subscriptionActionType === SubscriptionActionType.OPEN) {
        this.isTransfer = false
        this.headerText = this.translate.instant("Open")
        this.footerText = this.translate.instant("Open")
        this.toNumber = this.translate.instant("RoomNumber")
        this.toSide = this.translate.instant("Cote")
        this.toCommandText = this.translate.instant("OrderSummary")
      } else if (this.subscriptionActionType === SubscriptionActionType.TRANSFER) {
        this.isTransfer = true
        this.headerText = this.translate.instant("Transfer")
        this.footerText = this.translate.instant("Transfer")
        this.toNumber = this.translate.instant("RoomNumber")
        this.toSide = this.translate.instant("Cote")
        this.toCommandText = this.translate.instant("OrderSummary")
      } else {
        this.isTransfer = false
        this.headerText = this.translate.instant("Extension")
        this.footerText = this.translate.instant("Extend")
        this.toNumber = this.translate.instant("SubscriptionStart")
        this.toSide = this.translate.instant("SubscriptionEnd")
        this.toCommandText = this.translate.instant("OrderSummary")
      }
    }
  }

  getPackageName(packageId: string): string {
    if (this.institution) {
      let channel
      if (this.subscriptionOrder.tv) {
        channel = this.institution.channelPackages.find((myPackage) => myPackage.id === packageId)
      }
      if (this.subscriptionOrder.wifi) {
        channel = this.institution.wifiPackages.find((myPackage) => myPackage.id === packageId)
      }
      return channel ? channel.name : ""
    } else {
      return ""
    }
  }

  getSubscriptionEnd(periodInDays: number): Date {
    if (this.subscriptionStart) {
      return new Date(this.subscriptionStart.getTime() + periodInDays * (1000 * 60 * 60 * 24))
    } else {
      return new Date()
    }
  }
}
