import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { HeadsetSidebarModel } from "../../models/headset-siderbar.model"
import { Subscription } from "rxjs"

@Component({
  selector: "app-headset-sidebar",
  templateUrl: "./headset-sidebar.component.html",
  styleUrls: ["./headset-sidebar.component.scss"]
})
export class HeadsetSidebarComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription()

  @Input() sidebarOpened = false
  @Input() headsetOrder: HeadsetSidebarModel
  @Output() toggle: EventEmitter<any> = new EventEmitter()

  constructor(
    public translate: TranslateService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  close(): void {
    this.toggle.emit({})
  }
}
