import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import {ConfigService} from "../../shared/services/config.service"
import {EmailService} from "../../shared/services/email/email.service"
import {Email} from "../../shared/interfaces/email"

@Component({
  selector: "app-wifi-access-code",
  templateUrl: "./wifi-access-code.component.html",
  styleUrls: ["./wifi-access-code.component.css"]
})
export class WifiAccessCodeComponent {

  @Input()
  accessCode: string

  @Input()
  passWord?: string

  @Input()
  email?: string

  constructor(
    private configService: ConfigService,
    private router: Router
  ) {}

  goToWifiConnection(): void {
    this.configService.setAccessCode(this.accessCode)
    this.router.navigate(["/wifi-connection"])
  }
}

