import { Injectable } from "@angular/core"
import { environment } from "src/environments/environment"

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  private emulateDevices = false
  private institutionId: string = null
  private customerId = "id-root"
  private accessCode = null

  constructor() { }

  getInstitutionId(): string {
    return this.institutionId
  }

  getSessionId(): string {
    return localStorage.getItem("sid")
  }

  getCustomerId(): string {
    return this.customerId
  }

  setInstitutionId(institutionId: string): void {
    this.institutionId = institutionId
  }

  setSessionId(sessionId: string): void {
    localStorage.setItem("sid", sessionId)
  }

  setCustomerId(customerId: string): void {
    this.customerId = customerId
  }

  setAccessCode(accessCode: string): void {
    this.accessCode = accessCode
  }

  getAccessCode(): string {
    return this.accessCode
  }

  setEmulateDevices(emulateDevices: boolean): void {
    this.emulateDevices = emulateDevices
    if (this.emulateDevices) {
      console.log("devices (printer and payment terminal) are emulated")
    }
  }

  isEmulateDevices(): boolean {
    return !environment.production && this.emulateDevices
  }
}
