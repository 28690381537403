import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import { Subscription } from "rxjs"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { Institution } from "../../shared/interfaces/institution"
import { Tv } from "../../shared/interfaces/commendable"
import { TvLocation } from "../../shared/interfaces/subscription"
import { TvService } from "../../shared/services/tv/tv.service"

@Component({
  selector: "app-tv-select",
  templateUrl: "./tv-select.component.html",
  styleUrls: ["./tv-select.component.scss"]
})
export class TvSelectComponent implements OnChanges, OnDestroy {

  subscription: Subscription = new Subscription()

  @Input()
  manageAvailability = true

  @Input()
  enableAction = false

  @Input()
  actionLabel = ""

  @Input()
  header = ""

  @Input()
  forceSelection = false

  @Input()
  allowNotAvailableSelect = false

  @Input()
  institution: Institution

  @Input()
  formGroup: FormGroup

  @Input()
  tvFormControlName: string

  @Input()
  healthDepartmentFormControlName: string

  @Input()
  roomNbFormControlName: string

  @Output()
  forceSelectionChanged: EventEmitter<boolean> = new EventEmitter()

  @Output()
  next: EventEmitter<void> = new EventEmitter()

  @Output()
  tvChanged: EventEmitter<Tv> = new EventEmitter()

  tvs: Tv[] = []
  availableTvs: Tv[] = []
  tvLocations: TvLocation[] = []
  availableTvLocations: TvLocation[] = []
  formValChangesSubscription: Subscription
  roomSideFormControlName = ""

  constructor(
    private tvService: TvService
  ) {
    // do nothing
  }

  ngOnDestroy(): void {
    this.formGroup.removeControl(this.roomSideFormControlName)
    this.subscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tvFormControlName && changes.tvFormControlName.currentValue) {
      const tvLocationFormControlName = changes.tvFormControlName.currentValue + "TvLocationItem"
      this.formGroup.addControl(tvLocationFormControlName, new FormControl(null, Validators.required))
      this.roomSideFormControlName = tvLocationFormControlName
    }

    if (changes.institution && changes.institution.currentValue) {
      const availableTvs$ = this.manageAvailability ? this.tvService.getAvailableTvs(this.institution.id)
        : this.tvService.getTvs(this.institution.id)

      this.subscription.add(
        this.tvService.getTvs(this.institution.id).subscribe(allTvs => {
          this.tvs = allTvs
          this.tvLocations = this.mapToTvLocations(allTvs)
        })
      )
      this.subscription.add(
        availableTvs$.subscribe(availableTvs => {
          this.availableTvs = availableTvs
          this.availableTvLocations = this.mapToTvLocations(availableTvs)
        })
      )
    }

    if (changes.formGroup) {
      if (this.formValChangesSubscription) {
        this.formValChangesSubscription.unsubscribe()
      }

      const newFormGroup  = changes.formGroup.currentValue
      if (newFormGroup) {
        this.formValChangesSubscription = this.formGroup.valueChanges.subscribe(state => {
          const selectedTvItem = state[this.tvFormControlName]
          const selectedTv = selectedTvItem ? this.tvs.find(tv => tv.id === selectedTvItem.id) : null
          this.tvChanged.emit(selectedTv)
        })
        this.subscription.add(this.formValChangesSubscription)
      } else {
        this.formValChangesSubscription = null
      }
    }
  }

  mapToTvLocations(tvs: Tv[]): TvLocation[] {
    return tvs.map(tv => {
      const department = this.institution.healthDepartments.find(dpt => dpt.rooms
        .some(room => room.id === tv.roomId))
      return {
        departmentName: department ? department.id : null,
        roomId: tv.roomId,
        roomSide: tv.side
      } as TvLocation
    })
  }

  onForceSelectionChanged(forceSelectionChanged: boolean): void {
    this.forceSelectionChanged.emit(forceSelectionChanged)
  }

  onTvLocationChanged(tvLocation: TvLocation): void {
    const tv = tvLocation ? this.tvs.find(tvIt =>
      (tvIt.roomId === tvLocation.roomId) && (tvIt.side === tvLocation.roomSide)
    ) : null
    this.formGroup.get(this.tvFormControlName).patchValue(tvLocation)
    this.formGroup.get(this.tvFormControlName).patchValue(tv)
  }

  goNext(): void {
    this.next.emit()
  }
}
