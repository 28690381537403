import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { webSocket } from "rxjs/webSocket"
import { InvocationResult } from "src/app/shared/interfaces/invocation-result"
import { environment } from "src/environments/environment"
import { Debit } from "../../interfaces/debit"
import { DebitDto } from "../../interfaces/dto/debit-dto"
import { PaymentInfo } from "../../interfaces/payment.info"
import { reconnectWebsocket } from "../../rxjs-operators"
import { PrinterInfo } from "../../interfaces/printer.info";

const RECONNECT_INTERVAL_IN_SECONDS = 10

@Injectable({
  providedIn: "root"
})
export class KioskApiService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  started(): Observable<boolean> {
    return this.httpClient.get(`${environment.kioskApi.baseUrl}/payment-terminal/started`).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  start(): Observable<any> {
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/payment-terminal/start`, {})
  }

  debit(amountInCents: number, ticketNb: string): Observable<Debit> {
    const debitDto: DebitDto = {
      amountInCents,
      ticketNb,
    }
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/payment-terminal/debit`, debitDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  printBill(billContent: string): Observable<boolean> {
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/ticket-printer/print`, { content: billContent, partialCut: true }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getPaymentInfos(): Observable<PaymentInfo> {
    return webSocket(`${environment.kioskApi.websocketBaseUrl}/payment-terminal/messages`).pipe(
      reconnectWebsocket(RECONNECT_INTERVAL_IN_SECONDS)
    )
  }

  getPrinterInfos(): Observable<PrinterInfo> {
    return webSocket(`${environment.kioskApi.websocketBaseUrl}/ticket-printer/messages`).pipe(
      reconnectWebsocket(RECONNECT_INTERVAL_IN_SECONDS)
    )
  }
}
