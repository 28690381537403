<div [formGroup]="parentForm">
    <label class="label-green">
        {{ label }}
    </label>
    <input [type]="type"
       (focus)="onInputFocus(parentForm.controls[controlName], label)"
       class="form-control secondary-input"
       [formControlName]="controlName"
       [required]="required"
    >
</div>
