import { Component, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { CustomerService } from "../../shared/services/customer/customer.service"
import { MediaService } from "../../shared/services/media/media.service"
import { mergeMap } from "rxjs/operators"
import { Subscription } from "rxjs"

@Component({
  selector: "app-idle",
  templateUrl: "./idle.component.html",
  styleUrls: ["./idle.component.scss"]
})

export class IdleComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription()


  constructor(
    private router: Router,
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public goToHomePage(): void {
    this.router.navigate(["home"])
  }
}
