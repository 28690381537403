export interface Product {
  id?: string,
  type: ProductType,
  delivered: boolean,
  patientId: string,
  paymentId?: string,
  actions?: ProductAction[]
}

export enum ProductType {
  HEADSET = "HEADSET"
}

export interface ProductAction {
  doneAt: string,
  type: ProductActionType,
}

export enum ProductActionType {
  BUY = "BUY",
  DELIVER = "DELIVER",
  CANCEL_DELIVERY = "CANCEL_DELIVERY"
}
