<mat-dialog-content class="payment-dialog__content" [ngSwitch]="state">
  <img class="payment-dialog__content__terminal" src="assets/images/verifone-terminal.png">
  <ng-container *ngSwitchCase="TpeState.LOADING">
    <mat-icon>hourglass_top</mat-icon>
    <p>{{ 'PAYMENT_DIALOG.PLEASE_WAIT' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="TpeState.WAITING">
    <mat-icon>payment</mat-icon>
    <p>{{ 'PAYMENT_DIALOG.FOLLOW_PAYMENT_TERMINAL_INFOS' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchCase="TpeState.SUCCESS">
    <mat-icon>check_circle</mat-icon>
    <p>{{ 'PAYMENT_DIALOG.PAYMENT_SUCCESS' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon>error</mat-icon>
    <p>{{ 'PAYMENT_DIALOG.PAYMENT_FAIL' | translate }}</p>
    <p>{{ 'RETRY_OR_CONTACT_SUPPORT' | translate }}</p>
  </ng-container>
  <div>{{ paymentInfo }}</div>
</mat-dialog-content>
<mat-dialog-actions class="payment-dialog__actions" *ngIf="state == TpeState.SUCCESS || state == TpeState.ERROR">
  <button mat-button mat-dialog-close>{{ 'CLOSE' | translate }}</button>
</mat-dialog-actions>
