<app-login (authenticateEvent)="onAuthenticated($event)" *ngIf="!authPatient"></app-login>
<div class="humehealth subscription-extend" *ngIf="authPatient && institution">
  <div class="humehealth__header subscription-extend__header">
    <app-customer-logo></app-customer-logo>
    <button type="button" class="humehealth__button-secondary humehealth__header__action" (click)='onToggleSidebar()'>
      <img src="assets/images/menu-icon.svg" alt="menu">
      <p>{{subscriptionOrder.subscriptionPayment.price}} €</p>
    </button>
    <app-cancel-button *ngIf="!userSubscriptions"></app-cancel-button>
  </div>

  <form class="subscription-extend__content" [formGroup]="extendForm" (ngSubmit)="doExtend()">
    <ng-container *ngIf="!userSubscriptions">
      <mat-horizontal-stepper class="humehealth__stepper" linear #stepper>
        <mat-step [completed]="false" [editable]="false">
          <ng-template matStepLabel>{{ 'ChooseYourTVBouquet' | translate }}</ng-template>
          <app-package-select [createSubscriptionOrder]='subscriptionOrder'
            [packages]='institution.channelPackages' (nextStep)="goToNextStep()">
          </app-package-select>
        </mat-step>
        <mat-step [completed]="false" [editable]="false">
          <ng-template matStepLabel>{{ 'ChooseYourPeriod' | translate }}</ng-template>
          <app-period-select [order]="subscriptionOrder" (nextStep)="goToPayStep()">
          </app-period-select>
        </mat-step>

        <mat-step [editable]="false">
          <ng-template matStepLabel>{{ 'ValidateYourOrder' | translate }}</ng-template>
          <div *ngIf="!useKioskApi">
            <app-stripe [parentForm]="extendForm" [institution]="institution"></app-stripe>
            <div class="humehealth__footer">
              <button type="submit" class="humehealth__button-primary" [disabled]="extendForm.invalid">{{ 'Validate' |
                translate }}</button>
            </div>
          </div>
          <div class="humehealth__stepper__kiosk" *ngIf="useKioskApi">
            <app-kiosk></app-kiosk>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </ng-container>

    <ng-container *ngIf="userSubscriptions && !complete">
      <app-send-invoice [tvSubscription]="userSubscriptions" (nextStep)="doComplete()">
      </app-send-invoice>
    </ng-container>

    <ng-container *ngIf="complete">
      <app-order-complete></app-order-complete>
    </ng-container>
  </form>
  <app-subscription-sidebar class="subscription-extend__sidebar" [sidebarOpened]="sideBarOpen"
    [subscriptionOrder]="subscriptionOrder" [subscriptionStart]="start" [subscriptionActionType]="action"
    [institution]="institution" (toggle)='onToggleSidebar()'>
  </app-subscription-sidebar>
</div>
