<div class="package-main">
  <div class="main-title">
    <p *ngIf="createSubscriptionOrder.tv" >{{ 'ChooseYourTVPackage' | translate }}</p>
    <p *ngIf="createSubscriptionOrder.wifi" >{{ 'ChooseYourWifiPackage' | translate }}</p>
  </div>
  <div class="package-box">
    <div class="box-main" *ngFor="let packageToDisplay of packagesToDisplay">
      <div class="box-title">
        <h4>{{packageToDisplay.name}}</h4>
      </div>
      <div class="package-detail">
        <div class="package-image">
          <img [src]="buildImageUrl(packageToDisplay)" alt="package">
        </div>
        <div class="package-detail__description">
          <div class="package-detail__description__recommended" *ngIf="packageToDisplay.recommended" i18n>
            Recommandé
          </div>
          <div class="package-content" *ngFor="let price of sortPricesByPeriodInDaysAsc(packageToDisplay.prices)">
            <div class="content-inner">
              <p>
                <span>{{price.periodInDays}} {{ 'Days' | translate }}</span> :
                {{price.value}}€
              </p>
            </div>
          </div>
          <div class="content-inner" *ngIf="packageToDisplay.uploadLimitInMB">
            <p style="margin-bottom: 10px"><span>{{ 'uploadLimitInMB' | translate }}</span> :
            {{packageToDisplay.uploadLimitInMB}}MB/s</p>
            <p style="margin-bottom: 10px"><span>{{ 'downloadLimitInMB' | translate }}</span> :
            {{packageToDisplay.downloadLimitInMB}}MB/s</p>
            <p style="margin-bottom: 10px"><span>{{ 'concurrentAccessNb' | translate }}</span> :
            {{packageToDisplay.concurrentAccessNb}}</p>
          </div>
        </div>
      </div>
      <div class="package-button">
        <button type="button" class="btn btn-outline-success secondary"
          (click)="selectPackage(packageToDisplay.id)">{{ 'ToChoose' | translate }}</button>
      </div>
    </div>
  </div>
</div>
