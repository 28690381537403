import { Component, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { ScreenService } from "src/app/shared/services/screen.service"

@Component({
  selector: "app-index",
  template: ""
})
export class IndexComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  constructor(
    private screenService: ScreenService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.screenService.isKiosk().subscribe(kiosk => {
        if (kiosk) {
          this.goToIdlePage()
        } else {
          this.goToHomePage()
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public goToHomePage(): void {
    this.router.navigate(["home"])
  }

  public goToIdlePage(): void {
    this.router.navigate(["idle"])
  }
}
