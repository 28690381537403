<div [formGroup]="formGroup" class="humehealth-details">
  <div *ngIf="showHeader" class="humehealth-details__header">{{ header }}</div>
  <ul class="tv-location-select-fields" *ngIf="institution">
    <li>
      <span class="humehealth-details__label" i18n>Chambre :</span>
      <mat-form-field>
        <mat-select [formControlName]="roomNbFormControlName" (selectionChange)="onSelectRoomNumber($event.value)"
                    (openedChange)="onOpenRoomNumber($event)" [compareWith]="sameRoomNbs">
          <mat-select-trigger>{{ formGroup?.get(roomNbFormControlName)?.value?.number }}</mat-select-trigger>
          <input type="text"
                 class="form-control secondary-input"
                 *ngIf="!(isKiosk$ | async)"
                 (input)="onSearchRoomNumber($event.target.value)"
                 #searchRoomNumber>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let roomNumber of filteredRoomNumbers" [value]="roomNumber"
                      class="tv-location-select-fields__select__option">
            <span>{{ roomNumber?.number }}</span>
            <ng-container *ngIf="roomNumber.available; else unavailable">
              <mat-icon class="tv-location-select-fields__select__option--available">check_circle_outline</mat-icon>
            </ng-container>
            <ng-template #unavailable>
              <mat-icon class="tv-location-select-fields__select__option--unavailable">warning_amber</mat-icon>
            </ng-template>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li *ngIf="institution.manageHealthDepartments">
      <span class="humehealth-details__label">{{"Service" | translate}} :</span>
      <mat-form-field>
        <mat-select formControlName="healthDepartment" (selectionChange)="onSelectHealthDepartment($event.value)"
                    (openedChange)="onOpenHealthDepartment($event)" [compareWith]="sameHealthDepartments">
          <input type="text"
                 class="form-control secondary-input"
                 *ngIf="!(isKiosk$ | async)"
                 (input)="onSearchHealthDepartment($event.target.value)"
                 #searchHealthDepartment>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let healthDepartment of filteredHealthDepartments" [value]="healthDepartment"
                      class="tv-location-select-fields__select__option">
            <span>{{ healthDepartment.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li *ngIf="institution.manageRoomSides">
      <span class="humehealth-details__label" i18n>{{"Cote" | translate}} :</span>
      <mat-form-field>
        <mat-select [formControlName]="tvLocationFormControlName" [compareWith]="sameTvLocationItems">
          <mat-select-trigger>{{ formGroup?.get(tvLocationFormControlName)?.value?.sideName }}</mat-select-trigger>
          <mat-option *ngFor="let tvLocationItem of tvLocationItems" [value]="tvLocationItem"
                      class="tv-location-select-fields__select__option">
            <span>{{ tvLocationItem.sideName }}</span>
            <ng-container *ngIf="tvLocationItem.available; else unavailable">
              <mat-icon class="tv-location-select-fields__select__option--available">check_circle_outline</mat-icon>
            </ng-container>
            <ng-template #unavailable>
              <mat-icon class="tv-location-select-fields__select__option--unavailable">warning_amber</mat-icon>
            </ng-template>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <ng-container *ngIf="allowNotAvailableSelect">
      <li>
        <mat-checkbox (change)="onForceSelectionChanged($event)">
          {{"IgnoreLocationUnavailability" | translate}}
        </mat-checkbox>
      </li>
    </ng-container>
  </ul>
  <ng-container *ngIf="formGroup.get(tvLocationFormControlName) as control">
    <ng-container *ngIf="control.invalid && (control.dirty || control.touched)">
      <div class="humehealth-form-hints">
        <span *ngIf="!control.errors.required && control.errors.notAvailable" i18n>
          {{"locationIsNotAvailable" | translate}}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <button *ngIf="enableAction" class="humehealth-details__action-btn humehealth-btn-lg" mat-raised-button
          [disabled]="!formGroup.valid" type="submit">
    {{ actionLabel }}</button>
</div>
