import { DecimalPipe } from "@angular/common"
import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "centsToEuros"
})
export class CentsToEurosPipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(Number(value) / 100, args)
  }
}
