<app-login (authenticateEvent)="onAuthenticated($event)" *ngIf="!authPatient"></app-login>
<div class="humehealth headset-order" *ngIf="authPatient">
  <div class="humehealth__header headset-order__header">
    <app-customer-logo></app-customer-logo>
    <button type="button" class="humehealth__button-secondary humehealth__header__action" (click)='onToggleSidebar()'>
      <img src="assets/images/menu-icon.svg" alt="menu">
      <p>{{headsetOrder && headsetOrder.headsetPrice ? headsetOrder.headsetPrice : '--,--'}} €</p>
    </button>
    <app-cancel-button *ngIf="!product"></app-cancel-button>
  </div>
  <form class="headset-order__content" [formGroup]="orderHeadsetForm" (ngSubmit)="orderHeadset()">
    <div class="humehealth__content">
      <ng-container *ngIf="!product">
        <app-stripe [parentForm]="orderHeadsetForm" [institution]="institution"></app-stripe>
        <div class="humehealth__footer">
          <button type="submit" class="humehealth__button-primary" [disabled]="orderHeadsetForm.invalid">
            {{ 'Validate' | translate }}</button>
        </div>
      </ng-container>

      <ng-container *ngIf="product && !complete">
        <app-send-invoice [paymentId]="product.paymentId" (nextStep)="doComplete()">
        </app-send-invoice>
      </ng-container>

      <ng-container *ngIf="complete">
        <app-order-complete [label]="headsetOrderedLabel"></app-order-complete>
      </ng-container>
    </div>
  </form>
  <app-headset-sidebar class="headset-order__sidebar" [sidebarOpened]="sideBarOpen" [headsetOrder]="headsetOrder"
    (toggle)='onToggleSidebar()'>
  </app-headset-sidebar>
</div>
