import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  private isLoading = new BehaviorSubject(false)
  private isLoaderOverriden = false
  constructor() { }

  startLoaderOverride(valueToOverride: boolean) {
    this.isLoading.next(valueToOverride)
    this.isLoaderOverriden = true
  }

  stopLoaderOverride(isLoading: boolean = false) {
    this.isLoaderOverriden = false
    this.isLoading.next(isLoading)
  }

  setLoader(isLoading: boolean) {
    if (!this.isLoaderOverriden) {
      this.isLoading.next(isLoading)
    }
  }

  getLoader(): BehaviorSubject<boolean> {
    return this.isLoading
  }
}
