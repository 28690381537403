import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { TranslateService } from "@ngx-translate/core"

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.css"]
})
export class ConfirmationModalComponent implements OnInit {

  constructor(private router: Router, private modal: NgbModal, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  navigateToHome(): void {
    this.router.navigateByUrl("index")
    this.modal.dismissAll()
  }

  closeModel(): void {
    this.modal.dismissAll()
  }

}
