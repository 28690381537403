import { Component, OnInit, EventEmitter, Output, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { mergeMap } from "rxjs/operators"

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.css"]
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  menuVisible = false

  @Output() toggle: EventEmitter<any> = new EventEmitter()

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  // Toggle sidenav component
  toggleSidenav(): void {
    this.menuVisible = !this.menuVisible
    document.body.classList.toggle("overlay-open")

    this.toggle.emit(this.menuVisible)
  }
}
