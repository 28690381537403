import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Subscription } from "rxjs"

export interface Step {
  name: string
  enabled: boolean
  completed: boolean
}

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.css"]
})

export class StepperComponent implements OnDestroy {

  subscription: Subscription = new Subscription()

  @Input() secondary = false
  @Input() selectedStep = 0
  @Input() steps: Step[] = []

  @Output() stepChange: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  goToStep(newStepNb): void {
    if (this.steps[newStepNb].enabled) {
      this.stepChange.emit(newStepNb)
    }
  }
}
