import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import * as Keycloak from "keycloak-js"
import { BehaviorSubject, combineLatest, from, Observable, ReplaySubject, Subject } from "rxjs"
import { distinct, map, switchMap, tap } from "rxjs/operators"
import { environment } from "src/environments/environment"

@Injectable({
  providedIn: "root"
})
export class KeycloakService {

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler)
  }

  static auth: any = {}
  static onTokenExpired$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  static authRefreshError = false

  private token$ = new ReplaySubject<string>(1)
  private refreshedToken$ = new Subject<string>()
  private httpClient: HttpClient

  static init(url: string, defaultRealm: string, clientId: string, accessToken: string, refreshToken: string, idToken: string) {

    const keycloakAuth: Keycloak.KeycloakInstance = Keycloak({
      url,
      realm: defaultRealm,
      clientId,
      "ssl-required": "external",
      "public-client": true,
      token: accessToken
    })

    KeycloakService.auth.loggedIn = true
    KeycloakService.auth.defaultRealm = defaultRealm
    keycloakAuth.init({ token: accessToken, refreshToken, idToken, checkLoginIframe: false })
    KeycloakService.auth.authz = keycloakAuth
  }

  getAccessToken(forceReloadKeycloackService: boolean = false): Observable<string> {
    if (!forceReloadKeycloackService && this.isAuthenticated() && !KeycloakService.authRefreshError) {
      return this.getToken()
    }
    return this.restartService()
  }

  restartService(): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders().append(
      "Content-Type",
      "application/x-www-form-urlencoded"
    )
    const body = new HttpParams()
      .set("client_id", environment.keycloak.clientId)
      .set("scope", "openid")
      .set("grant_type", "password")
      .set("username", environment.keycloak.username)
      .set("password", environment.keycloak.password)
    return this.httpClient.post(environment.keycloak.url + "/realms/" + environment.keycloak.realm
      + "/protocol/openid-connect/token", body.toString(), { headers }).pipe(
        tap((res: any) => {
          KeycloakService.onTokenExpired$.next(false)
          KeycloakService.init(environment.keycloak.url, environment.keycloak.realm, environment.keycloak.clientId,
            res.access_token, res.refresh_token, res.id_token)
          KeycloakService.authRefreshError = false
        }),
        map((res: any) => res.access_token)
      )
  }

  getTokenPromise(refresh: boolean = false): Promise<string> {
    let secondsBeforeExpiration = 90
    if (refresh) {
      secondsBeforeExpiration = -1
    }
    return new Promise<string>((resolve, reject) => {
      if (KeycloakService.auth.authz.token) {
        KeycloakService.auth.authz
          .updateToken(secondsBeforeExpiration) // refresh the token if it will expire in 90 seconds or less
          .success((refreshed) => {
            this.token$.next(KeycloakService.auth.authz.token)
            if (refreshed) {
              this.refreshedToken$.next(KeycloakService.auth.authz.token)
            }
            resolve(KeycloakService.auth.authz.token as string)
          })
          .error(() => {
            reject("Failed to refresh token")
          })
      } else {
        reject("Not logged in")
      }
    })
  }

  getToken(refresh: boolean = false): Observable<string> {
    return from(this.getTokenPromise(refresh))
  }

  getTokenObservable(): Observable<string> {
    return this.token$.pipe(distinct())
  }

  getAutoRefreshToken(): Observable<string> {
    this.getToken()
    return combineLatest([this.getTokenObservable(), KeycloakService.onTokenExpired$]).pipe(
      switchMap(_ => this.getToken()),
      distinct()
    )
  }

  getKeycloakAuth() {
    return KeycloakService.auth.authz
  }

  isAuthenticated(): boolean {
    return KeycloakService.auth && KeycloakService.auth.authz && KeycloakService.auth.authz.authenticated
  }

  getEmail(): string {
    return KeycloakService.auth.authz.tokenParsed.email
  }

  getUsername(): string {
    return KeycloakService.auth.authz.tokenParsed.preferred_username
  }

  getPhoneNumber(): string {
    return KeycloakService.auth.authz.tokenParsed.phoneNumber
  }

  getCustomerIds(): string[] {
    return KeycloakService.auth.authz.tokenParsed.cids
  }

  getApiUserId(): string {
    return KeycloakService.auth.authz.tokenParsed.apiUserId
  }


  getFirstName(): string {
    return KeycloakService.auth.authz.tokenParsed.given_name
  }

  getLastName(): string {
    return KeycloakService.auth.authz.tokenParsed.family_name
  }

  getFullName(): string {
    return KeycloakService.auth.authz.tokenParsed.name
  }

  getRefreshedToken(): Observable<string> {
    return this.refreshedToken$
  }

  redirectToAccountPage() {
    KeycloakService.auth.authz.accountManagement().success(() => {
      // do nothing
    })
  }

  getRealm(): string {
    return KeycloakService.auth.authz.realm
  }

  getDefaultRealm(): string {
    return KeycloakService.auth.defaultRealm
  }

  usesDefaultRealm(): boolean {
    return this.getDefaultRealm() === this.getRealm()
  }
}
