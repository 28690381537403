<form class="humehealth" [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="humehealth__body">
    <div class="humehealth__header">
      <app-customer-logo></app-customer-logo>
    </div>
    <div class="humehealth__content">
      <div class="humehealth__title">{{ 'IdentifyYourself' | translate }}</div>
      <div class="humehealth__sub-title">{{ 'LogInFillingBox' | translate }}</div>
      <div class="humehealth__wrap">
        <div class="humehealth__form-field">
          <app-custom-input label="{{ 'LastName' | translate }}"
                        controlName="lastName"
                        [parentForm]="loginForm"
                        required="true">
          </app-custom-input>
        </div>
        <div>
          <app-custom-input label="{{'CurrentRoomNo' | translate }}"
                        controlName="roomNumber"
                        [parentForm]="loginForm"
                        required="true">
          </app-custom-input>
        </div>
      </div>
      <div class="humehealth__wrap">
        <app-date-picker [parentForm]="loginForm"
                         controlForm="birthdate"
                         label="{{ 'DateOfBirth' | translate }}"
                         required="true">
        </app-date-picker>
      </div>
      <div class="humehealth__sub-title">
        {{ 'NotRegistered' | translate }}
        <a routerLink="{{ routerLink }}" routerLinkActive="active">
          {{ 'OpenSubscription' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="humehealth__footer">
    <button type="submit" class="humehealth__button-primary" [disabled]="!loginForm.valid">
      {{ 'Validate' | translate }}
    </button>
  </div>
</form>
