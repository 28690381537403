import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Observable, Subscription } from "rxjs"
import { mergeMap } from "rxjs/operators"
import { AuthPatient } from "src/app/shared/interfaces/auth-patient"
import { CreateProduct } from "src/app/shared/interfaces/create-product"
import { Department } from "src/app/shared/interfaces/department"
import { Institution } from "src/app/shared/interfaces/institution"
import { Product, ProductType } from "src/app/shared/interfaces/product"
import { Room } from "src/app/shared/interfaces/room"
import { Tv } from "src/app/shared/interfaces/commendable"
import { HeadsetSidebarModel } from "src/app/shared/models/headset-siderbar.model"
import { InstitutionService } from "src/app/shared/services/institution.service"
import { ProductService } from "src/app/shared/services/product.service"
import { LoaderService } from "src/app/shared/utilities/loader/loader.service"
import { StripeComponent } from "../stripe/stripe.component"

@Component({
  selector: "app-headset-order",
  templateUrl: "./headset-order.component.html",
  styleUrls: ["./headset-order.component.scss"]
})
export class HeadsetOrderComponent implements OnInit, OnDestroy {
  @ViewChild(StripeComponent) stripe: StripeComponent

  subscriptions: Subscription = new Subscription()

  complete = false
  sideBarOpen = false

  institution: Institution

  authPatient: AuthPatient

  headsetOrder: HeadsetSidebarModel
  headsetOrderedLabel: string

  product: Product
  orderHeadsetForm: FormGroup

  constructor(
    private institutionService: InstitutionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loaderService: LoaderService,
    private productService: ProductService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.headsetOrderedLabel = this.translateService.instant("HeadsetOrdered")
    this.orderHeadsetForm = this.formBuilder.group({})
    this.subscriptions.add(
      this.institutionService.getInstitutionWithNestedResources().subscribe(
        institution => {
          this.institution = institution
        }
      )
    )
  }

  onAuthenticated(authPatient: AuthPatient): void {
    const department = this.getDepartment(authPatient.subscription.roomId)
    const room = this.getRoom(authPatient.subscription.roomId)
    const tv = this.getTv(authPatient.subscription.tvId)
    this.headsetOrder = {
      departmentName: department.name,
      roomNumber: room.number,
      roomSide: tv.side,
      headsetPrice: this.institution.headsetPrice.toString()
    }
    this.authPatient = authPatient
  }


  orderHeadset(): void {
    this.loaderService.startLoaderOverride(true)
    this.subscriptions.add(
      this.pay(this.institution.headsetPrice.toString()).pipe(
        mergeMap(paymentConfirmed => {
          const product: CreateProduct = {
            type: ProductType.HEADSET,
            delivered: false,
            patientId: this.authPatient.patient.id,
            paymentReference: paymentConfirmed
          }
          return this.productService.createProduct(this.authPatient, product)
        })
      ).subscribe(
        product => {
          this.loaderService.stopLoaderOverride()
          this.product = product
        },
        err => {
          console.log(err)
          this.loaderService.stopLoaderOverride()
          this.router.navigateByUrl("error")
        }
      )
    )
  }

  pay(amount: string): Observable<any> {
    return this.stripe.pay(amount)
  }

  doComplete(): void {
    this.complete = true
  }

  onToggleSidebar(): void {
    this.sideBarOpen = !this.sideBarOpen
  }

  getDepartment(roomId: string): Department {
    return this.institution.healthDepartments.find(department => department.rooms.some(room => room.id === roomId))
  }

  getTv(tvId: string): Tv {
    return this.institution.tvs.find(tv => tv.id === tvId)
  }

  getRoom(roomId: string): Room {
    return this.getDepartment(roomId).rooms.find(room => room.id === roomId)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
