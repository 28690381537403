import {Component, Inject} from "@angular/core"
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet"

@Component({
  selector: "app-bottom-sheet-overview",
  templateUrl: "./bottom-sheet-overview.component.html",
  styleUrls: ["./bottom-sheet-overview.component.css"],
})
export class BottomSheetOverviewComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewComponent>
  ) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss()
    event.preventDefault()
  }
}
