import { Component, OnDestroy, OnInit } from "@angular/core"
import { map, mergeMap } from "rxjs/operators"
import { Observable, Subscription } from "rxjs"
import { CustomerService } from "../../services/customer/customer.service"
import { MediaService } from "../../services/media/media.service"
import { InstitutionService } from "../../services/institution.service"

@Component({
  selector: "app-customer-logo",
  templateUrl: "./customer-logo.component.html",
  styleUrls: ["./customer-logo.component.css"]
})
export class CustomerLogoComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  mediaUrl = null

  constructor(
    private institutionService: InstitutionService,
    private customerService: CustomerService,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.initLogo().subscribe(url => this.mediaUrl = url))
  }

  initLogo(): Observable<string> {
    return this.institutionService.getInstitution().pipe(
      mergeMap(institution => {
        return this.mediaService.getMedia(institution.logoMediaId)
      }),
      map(media => {
        return media.url
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
