import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import "rxjs/add/operator/do"
import { filter, finalize, map, mergeMap } from "rxjs/operators"
import { environment } from "../../../environments/environment"
import { KeycloakService } from "../services/keycloak.service"
import { LoaderService } from "../utilities/loader/loader.service"

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private keycloakService: KeycloakService,
    public loaderService: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.loaderService.setLoader(true)
    if (request.url.startsWith(environment.kioskApi.baseUrl)) {
      const basicAuthValue = btoa(`${environment.kioskApi.user}:${environment.kioskApi.password}`)
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${basicAuthValue}`
        }
      })
      return next.handle(request).pipe(finalize(() => this.loaderService.setLoader(false)))
    } else if (request.url.startsWith(
      `${environment.keycloak.url}/realms/${environment.keycloak.realm}/protocol/openid-connect/token`)) {
      return next.handle(request).pipe(
        filter(httpEvent => httpEvent instanceof HttpResponse),
        map(httpEvent => httpEvent as HttpResponse<any>),
        mergeMap(response => {
          if (response.status == 400) {
            return this.keycloakService.getAccessToken(true)
          } else { return of(response) }
        }),
        finalize(() => this.loaderService.setLoader(false))
      )
    } else if (this.keycloakService.isAuthenticated()) {
      return this.keycloakService.getAccessToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            })
          }
          return next.handle(request).pipe(finalize(() => this.loaderService.setLoader(false)))
        }))
    }
    return next.handle(request).pipe(finalize(() => this.loaderService.setLoader(false)))
  }
}
