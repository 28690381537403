import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { Observable } from "rxjs"
import { ScreenService } from "src/app/shared/services/screen.service"
import { SubscriptionService } from "src/app/shared/services/subscription.service"
import {CreateUserSubscriptionOrderOpen} from "../../shared/interfaces/create-user-subscription-order"

@Component({
  selector: "app-period-select",
  templateUrl: "./period-select.component.html",
  styleUrls: ["./period-select.component.scss"]
})
export class PeriodSelectComponent implements OnInit {

  kiosk$: Observable<boolean>

  @Input() order: CreateUserSubscriptionOrderOpen
  @Output() nextStep: EventEmitter<any> = new EventEmitter()
  @ViewChild("customPeriod") customPeriodElement: ElementRef

  customPeriodValue = new FormControl()

  predefinedPeriodInDays = [ 2, 3, 5, 7 ]

  constructor(
    private screenService: ScreenService,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit(): void {
    this.kiosk$ = this.screenService.isKiosk()
  }

  selectPeriod(periodInDays: number): void {
    this.subscriptionService.computePrice(
      periodInDays,
      this.order.tv ? this.order.tv.channelPackageId : null,
      this.order.wifi ? this.order.wifi.wifiPackageId : null
    ).subscribe(price => {
      this.order.subscriptionPayment.price = price
    })
    if (this.order.tv) {
      this.order.tv.periodInDays = periodInDays
    }
    if (this.order.wifi) {
      this.order.wifi.periodInDays = periodInDays
    }
  }

  selectCustomPeriod($event): void {
    this.selectPeriod($event.target.value)
  }

  selectCustomPeriodChoice(): void {
    this.customPeriodElement.nativeElement.focus()
    this.selectPeriod(this.customPeriodElement.nativeElement.value)
  }

  goToNextStep(): void {
    this.nextStep.emit({})
  }
}
